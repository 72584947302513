import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Alert } from 'src/app/shared/models/alert';
import { AlertContext } from 'src/app/shared/models/enums/alert-context.enum';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ReCaptchaV3Service} from 'ng-recaptcha';

import { PersonDto } from 'src/app/shared/generated/model/person-dto';
import { AlertService } from 'src/app/shared/services/alert.service';
import { environment } from 'src/environments/environment';
import { SupportRequestService } from 'src/app/shared/generated/api/support-request.service';
import { SupportRequestLogUpsertDto } from 'src/app/shared/generated/model/support-request-log-upsert-dto';
import { SupportRequestTypeDto } from 'src/app/shared/generated/model/support-request-type-dto';
import { LTInfoAreaDto } from 'src/app/shared/generated/model/lt-info-area-dto';
import { LTInfoRoleEnum } from 'src/app/shared/models/enums/lt-info-role.enum';
import { LTInfoAreaEnum } from 'src/app/shared/models/enums/lt-info-area.enum';


@Component({
  selector: 'tdrmarketplace-request-support',
  templateUrl: './request-support.component.html',
  styleUrls: ['./request-support.component.scss']
})
export class RequestSupportComponent implements OnInit {
  public currentUser: PersonDto;
  
  public tdrListingID: number;
  public supportRequestLogModel: SupportRequestLogUpsertDto;
  public createPersonDisplayName: string;
  public createPersonEmail: string;
  public invalidFields: Array<string> = [];
  public isLoadingSubmit = false;
  public originalSupportRequestLogModel: string;
  public supportRequesTypes: Array<SupportRequestTypeDto>;


  constructor(
    private router: Router,
    private cdr: ChangeDetectorRef,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private supportRequestService: SupportRequestService,
    private recaptchaV3Service: ReCaptchaV3Service,

  ) { }

  canExit(){
    return this.originalSupportRequestLogModel == JSON.stringify(this.supportRequestLogModel);
  };

  ngOnInit(): void {
    this.supportRequestLogModel = new SupportRequestLogUpsertDto();
    this.supportRequestLogModel.LTInfoAreaID = LTInfoAreaEnum.Marketplace;
    this.supportRequestService.supportRequestTypesGet().subscribe(supportRequesTypes => {
      this.supportRequesTypes = supportRequesTypes;
    })

    this.authenticationService.getCurrentUser().subscribe(currentUser => {
      this.currentUser = currentUser;
      this.originalSupportRequestLogModel = "";
      this.cdr.detectChanges();
    });


  }

  ngOnDestroy() {
    this.cdr.detach();
  }


  public isFieldInvalid(fieldName: string) {
    return this.invalidFields.indexOf(fieldName) > -1;
  }

  private onSubmitSuccess(successMessage: string) {
    this.isLoadingSubmit = false;  
    this.originalSupportRequestLogModel = JSON.stringify(this.supportRequestLogModel);

    const rerouteURL = "";

    this.router.navigateByUrl(rerouteURL).then(() => {
      this.alertService.pushAlert(new Alert(successMessage, AlertContext.Success));
    });
    window.scroll(0,0);
  }

  private onSubmitFailure(error) {
    if (error.error?.errors) {
      for (let key of Object.keys(error.error.errors)) {
        this.invalidFields.push(key);
      }
    }
    this.isLoadingSubmit = false;
    window.scroll(0,0);
    this.cdr.detectChanges();
  }

  public onSubmit(supportRequestForm: HTMLFormElement): void {
    this.isLoadingSubmit = true;
    this.invalidFields = [];
    this.alertService.clearAlerts();
    
    if(this.currentUser){
      this.supportRequestLogModel.RequestPersonID = this.currentUser.PersonID;
      this.supportRequestLogModel.RequestPersonName = this.currentUser.FullName;
      this.supportRequestLogModel.RequestPersonEmail = this.currentUser.Email;

      this.supportRequestService.requestSupportPost(this.supportRequestLogModel).subscribe(listing => {
        this.onSubmitSuccess("Your Support Request was successfully submitted.")
      }, error => {
        this.onSubmitFailure(error);
      });
    } else {
      // check recaptcha
      this.recaptchaV3Service.execute('submitFeedback').subscribe(token => {
        this.supportRequestLogModel.Token = token;
        this.supportRequestService.requestSupportPost(this.supportRequestLogModel).subscribe(listing => {
          this.onSubmitSuccess("Your Support Request was successfully submitted.")
        }, error => {
          this.onSubmitFailure(error);
        });
      }, error => {
        this.isLoadingSubmit = false;
        this.alertService.pushAlert(new Alert(`There was an error performing Recaptcha validation. Please try again`, AlertContext.Danger, true));
      });
    }

    
  }
}

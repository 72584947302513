<ng-container *ngIf="listingModel">
    <h2 class="d-inline-block">
        Offer Details
    </h2>
    <custom-rich-text [customRichTextTypeID]="introRichTextTypeID"></custom-rich-text>
</ng-container>
<form (ngSubmit)="onSubmit(createListingForm)" #createListingForm="ngForm" *ngIf="listingModel">
    <div class="row">
        <div class="col-7 verticalBar pr-4">
            <div class="row mt-1">
                <div class="col-sm-3 control-label text-sm-right">
                    <label>Parcel APN</label>
                </div>
                <div class="col-sm-9 font-weight-bold">
                    <div>
                        <a href="{{parcel?.DetailUrl}}">{{ parcel?.ParcelNumber }}</a>
                    </div>
                </div>
            </div>
            <div class="row mt-1">
                <div class="col-sm-3 control-label text-sm-right">
                    <label>Address</label>
                </div>
                <div class="col-sm-9 font-weight-bold">
                    <div>
                        {{ parcel?.ParcelStreetAddress }}
                        <br/>
                        {{ parcel?.ParcelCity }}, {{ parcel?.ParcelState }} {{ parcel?.ParcelZipCode}}
                        
                    </div>
                </div>
            </div>
            <div class="row mt-1">
                <div class="col-sm-3 control-label text-sm-right">
                    <label>Jurisdiction</label>
                </div>
                <div class="col-sm-9 font-weight-bold">
                    <div>
                        {{ parcel?.Jurisdiction.Organization.OrganizationName }}
                    </div>
                </div>
            </div>
            <div class="row mt-1">
                <div class="col-sm-3 control-label text-sm-right">
                    <label>HRA</label>
                </div>
                <div class="col-sm-9 font-weight-bold">
                    <div>
                        {{ parcel?.HRA }}
                    </div>
                </div>
            </div>
        </div>
        <div class="col-5 pl-4">
            
        </div>
    </div>
    <div class="row">
        <div class="col-7 verticalBar pr-4">
            <h5 class="mt-4">Development Right & Land Capability</h5>       
            <div class="row mt-4">
                <div class="col-sm-3 control-label text-sm-right">
                    <label class="required">Development Right</label>
                </div>
                <div class="col-sm-9">
                    <ng-select [(ngModel)]="listingModel.LandCapabilityTypeID"
                        [items]="landCapabilityTypes" bindLabel="DisplayName" bindValue="LandCapabilityTypeID" 
                        placeholder="Choose One" name="LandCapabilityTypeID" required (change)="updateQuantityAppendText($event)"
                        [ngClass]='{"field-invalid": isFieldInvalid("LandCapabilityTypeID")}'>
                    </ng-select>
                </div>
            </div>
        </div>
        <div class="col-5 pl-4">
            <div class="mt-4">
                <custom-rich-text [customRichTextTypeID]="developmentRightHelpRichTextTypeID"></custom-rich-text>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-7 verticalBar pr-4">
            <h5 class="mt-4">Offer</h5>
            <div class="row mt-3">
                <div class="col-sm-3 control-label text-sm-right">
                    <label class="required">Quantity</label>
                </div>
                <div class="col-sm-9 input-group">
                    <input type="number" min="0" class="form-control" name="transfer-quantity" [(ngModel)]="listingModel.TransferQuantity"
                    [ngClass]='{"field-invalid": isFieldInvalid("TransferQuantity")}'>
                    <div *ngIf="quantityAppendText" class="input-group-append">
                        <span class="input-group-text">{{quantityAppendText}}</span>
                      </div>
                </div>
            </div>
        
            <div class="row mt-3">
                <div class="col-sm-3 control-label text-sm-right">
                    <label class="required">Unit Price</label>
                </div>
                <div class="col-sm-9 input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text">$</span>
                      </div>
                    <input type="number" min="0" class="form-control" name="unit-price" [(ngModel)]="listingModel.UnitPrice"
                    [ngClass]='{"field-invalid": isFieldInvalid("UnitPrice")}'>
                </div>
            </div>
        
            <div class="row mt-3">
                <div class="col-sm-3 control-label text-sm-right">
                    <label>Total Price</label>
                </div>
                <div class="col-sm-9">
                    <span>{{getTotalPrice()}}</span>
                </div>
            </div>
        
            <div class="row mt-3">
                <div class="col-sm-3 control-label text-sm-right">
                    <label>Notes on Listing</label>
                </div>
                <div class="col-sm-9">
                    <textarea class="form-control" name="notes" style="height: 100px" placeholder=""
                        [(ngModel)]="listingModel.Notes">
                    </textarea>
                    <em *ngIf="!listingModel.Notes" class="text-muted">Characters Remaining: 500</em>
                    <em *ngIf="listingModel.Notes && listingModel.Notes.length <= 500" class="text-muted">Characters Remaining: {{500 - listingModel.Notes.length}}</em>
                    <em *ngIf="listingModel.Notes && listingModel.Notes.length > 500" class="text-danger">Characters Remaining: {{500 - listingModel.Notes.length}}</em>
                </div>
            </div>
        </div>
        <div class="col-5 pl-4">
            <div class="mt-4">
                <custom-rich-text [customRichTextTypeID]="offerHelpRichTextTypeID"></custom-rich-text>
            </div>
        </div>
    </div>

    <div class="row mt-4">
        <div class="col-sm-2">
            <i class="fas fa-bolt"></i> Required field
        </div>
        <div class="col-sm-5 text-right">
            <button type="submit" class="btn btn-outline-primary mr-2" [disabled]="isLoadingSubmit || isLoadingPublish">
                <span *ngIf="isLoadingSubmit" class="fa fa-spinner loading-spinner"></span>
                Save
            </button>


            <a *ngIf="!listingModel.TdrListingID" class="btn btn-secondary ml-1" routerLink="/my-listings">Cancel</a>
            <a *ngIf="listingModel.TdrListingID" class="btn btn-secondary ml-1" routerLink="../../">Cancel</a>
        </div>
    </div>
    <div class="row mt-4" *ngIf="showPublishButton()">
        <div class="col-sm-7 text-right">
            <button (click)="onPublish()" class="btn btn-tdrmarketplace" [disabled]="isLoadingSubmit || isLoadingPublish || !canPublishListing()">
                <span *ngIf="isLoadingPublish" class="fa fa-spinner loading-spinner"></span>
                Publish
            </button>
        </div>
    </div>
</form>

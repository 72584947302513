<nav aria-label="breadcrumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item active" aria-current="page">Help</li>
    </ol>
</nav>
<!--RRB/EDF's names should or should not be configurated on this page?-->
<div class="container">
    <app-alert-display></app-alert-display>
    <h2 class="d-inline-block">Help</h2>
    <custom-rich-text [customRichTextTypeID]="richTextTypeID"></custom-rich-text>
</div>
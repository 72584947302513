import { Component, OnInit } from '@angular/core';
import { LandCapabilityTypeService } from 'src/app/shared/generated/api/land-capability-type.service';
import { CommoditySimpleDto } from 'src/app/shared/generated/model/commodity-simple-dto';
import { CommodityEnum } from 'src/app/shared/models/enums/commodity.enum';

@Component({
  selector: 'tdrmarketplace-converstion-calculator',
  templateUrl: './converstion-calculator.component.html',
  styleUrls: ['./converstion-calculator.component.scss']
})
export class ConverstionCalculatorComponent implements OnInit {
  public commodities: Array<CommoditySimpleDto>;

  public existingCommodity: CommoditySimpleDto;
  public convertedToCommodity: CommoditySimpleDto;
  public remainderCommodity: CommoditySimpleDto;

  public existingQuantity: number;
  public existingQuantityUnit: string;

  public convertedToQuantity: string;
  public convertedToQuantityUnit: string;

  public remainderQuantity: string;
  public remainderQuantityUnit: string;

  constructor(
    private landCapabilityTypeService: LandCapabilityTypeService
  ) { }


  ngOnInit(): void {
    this.landCapabilityTypeService.commoditiesGet().subscribe(commodities => {
      this.commodities = commodities.filter(c =>
        c.CommodityID == CommodityEnum.CommercialFloorArea ||
        c.CommodityID == CommodityEnum.TouristAccommodationUnit ||
        c.CommodityID == CommodityEnum.SingleFamilyResidentialUnitOfUse ||
        c.CommodityID == CommodityEnum.MultiFamilyResidentialUnitOfUse);
      var cfaCommodity = commodities.find(c => c.CommodityID == CommodityEnum.CommercialFloorArea);
      this.existingQuantityUnit = cfaCommodity?.CommodityUnitType?.CommodityUnitTypeAbbreviation;
      this.convertedToQuantityUnit = cfaCommodity?.CommodityUnitType?.CommodityUnitTypeAbbreviation;
      this.remainderQuantityUnit = cfaCommodity?.CommodityUnitType?.CommodityUnitTypeAbbreviation;
      this.existingCommodity = cfaCommodity;
      this.convertedToCommodity = cfaCommodity;
      this.remainderCommodity = cfaCommodity;

      this.convertedToQuantity = "0.0";
      this.remainderQuantity = "0.0";
    })
  }

  public updateExistingCommodity(event) {
    this.existingQuantityUnit = this.existingCommodity.CommodityUnitType?.CommodityUnitTypeAbbreviation;
    this.recalculate();
  }

  public updateConvertedToCommodity(event) {
    this.convertedToQuantityUnit = this.convertedToCommodity.CommodityUnitType?.CommodityUnitTypeAbbreviation;
    this.recalculate();
  }

  public updateRemainderCommodity(event) {
    this.remainderQuantityUnit = this.remainderCommodity.CommodityUnitType?.CommodityUnitTypeAbbreviation;
    this.recalculate();
  }

  public onQuantityChange(event) {
    this.recalculate();
  }


  private recalculate() {
    var cfaToOthers: { [key: string]: number } = {}
    cfaToOthers.CommercialFloorArea = 1;
    cfaToOthers.SingleFamilyResidentialUnitOfUse = (1 / 300);
    cfaToOthers.TouristAccommodationUnit = (1 / 300);
    cfaToOthers.MultiFamilyResidentialUnitOfUse = (1 / 200);

    var sfruuToOthers: { [key: string]: number } = {}
    sfruuToOthers.CommercialFloorArea = 300;
    sfruuToOthers.SingleFamilyResidentialUnitOfUse = 1;
    sfruuToOthers.TouristAccommodationUnit = 1;
    sfruuToOthers.MultiFamilyResidentialUnitOfUse = (3 / 2);

    var tauToOthers: { [key: string]: number } = {}
    tauToOthers.CommercialFloorArea = 300;
    tauToOthers.SingleFamilyResidentialUnitOfUse = 1;
    tauToOthers.TouristAccommodationUnit = 1;
    tauToOthers.MultiFamilyResidentialUnitOfUse = (3 / 2);

    var mfruuToOthers: { [key: string]: number } = {}
    mfruuToOthers.CommercialFloorArea = 200;
    mfruuToOthers.SingleFamilyResidentialUnitOfUse = (2 / 3);
    mfruuToOthers.TouristAccommodationUnit = (2 / 3);
    mfruuToOthers.MultiFamilyResidentialUnitOfUse = 1;

    var commodityConversionMatrix = {
      CommercialFloorArea: cfaToOthers,
      SingleFamilyResidentialUnitOfUse: sfruuToOthers,
      TouristAccommodationUnit: tauToOthers,
      MultiFamilyResidentialUnitOfUse: mfruuToOthers
    };

    if(this.existingQuantity != null && this.existingQuantity != undefined){
      var convertedValue = this.existingQuantity * commodityConversionMatrix[this.existingCommodity.CommodityName][this.convertedToCommodity.CommodityName]
      this.convertedToQuantity= Math.floor(convertedValue).toFixed(1);
      var remainderValue = convertedValue % 1;
      this.remainderQuantity =  (remainderValue * commodityConversionMatrix[this.convertedToCommodity.CommodityName][this.remainderCommodity.CommodityName]).toFixed(1);
    }
  }

}

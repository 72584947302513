/**
 * LtInfo.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export class TdrListingUpsertDto { 
    TdrListingID?: number;
    TdrListingTypeID: number;
    ParcelID?: number;
    ParcelNumber: string;
    LandCapabilityTypeID?: number;
    UnitPrice?: number;
    TransferQuantity?: number;
    CreatePersonID?: number;
    OrganizationName?: string;
    PhoneNumber?: string;
    AdditionalContactInformation?: string;
    Notes?: string;
    constructor(obj?: any) {
        Object.assign(this, obj);
    }
}

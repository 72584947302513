import {
  Component,
  OnInit,
  ChangeDetectorRef,
  OnDestroy,
  ViewChild,
} from "@angular/core";
import { PersonDto } from "src/app/shared/generated/model/person-dto";
import { TdrListingService } from "src/app/shared/generated/api/tdr-listing.service";
import { AuthenticationService } from "src/app/services/authentication.service";
import { DatePipe, DecimalPipe, CurrencyPipe } from "@angular/common";
import { ColDef, GridOptions } from "ag-grid-community";
import { LinkRendererComponent } from "src/app/shared/components/ag-grid/link-renderer/link-renderer.component";
import { ExternalLinkRendererComponent } from "src/app/shared/components/ag-grid/external-link-renderer/external-link-renderer.component";

import { AgGridAngular } from "ag-grid-angular";
import { UtilityFunctionsService } from "src/app/services/utility-functions.service";
import { CustomRichTextType } from 'src/app/shared/models/enums/custom-rich-text-type.enum';
import { FieldDefinitionGridHeaderComponent } from "src/app/shared/components/field-definition-grid-header/field-definition-grid-header.component";
import { CustomDropdownFilterComponent } from "src/app/shared/components/custom-dropdown-filter/custom-dropdown-filter.component";

@Component({
  selector: "tdrmarketplace-listing-list",
  templateUrl: "./listing-list.component.html",
  styleUrls: ["./listing-list.component.scss"],
})
export class ListingListComponent implements OnInit, OnDestroy {
  @ViewChild("listingsGrid") listingsGrid: AgGridAngular;

  public currentPerson: PersonDto;

  public richTextTypeID: number = CustomRichTextType.TDRListingList;
  public gridOptions: GridOptions;
  public listings = [];
  columnDefs: any;

  constructor(
    private cdr: ChangeDetectorRef,
    private authenticationService: AuthenticationService,
    private utilityFunctionsService: UtilityFunctionsService,
    private tdrListingService: TdrListingService,
  ) { }

  ngOnInit() {
    this.authenticationService.getCurrentUser().subscribe((currentUser) => {
      this.currentPerson = currentUser;
    });

    this.gridOptions = <GridOptions>{};
    this.gridOptions.overlayNoRowsTemplate = "No Published Listings";
    this.listingsGrid?.api.showLoadingOverlay();
    this.tdrListingService.publishedListingsGet().subscribe((listings) => {
      this.listings = listings;
      this.listingsGrid.api.sizeColumnsToFit();
      this.listingsGrid.api.hideOverlay();
      this.cdr.detectChanges();
    });

    this.columnDefs = [
      {
        valueGetter: (params: any) => {
          return { LinkValue: params.data.TdrListingID, LinkDisplay: "View", CssClasses: "btn btn-tdrmarketplace btn-sm" };
        }, cellRendererFramework: LinkRendererComponent,
        cellRendererParams: { inRouterLink: "/listings/", },
        width: 62, sortable: false, filter: false
      },
      this.createDateColumnDef('Posting Date', 'PostingDate', 'M/d/yyyy'),
      {
        headerName: 'Type', field: 'TdrListingType.TdrListingTypeDisplayName', sortable: true, width: 125,
        filterFramework: CustomDropdownFilterComponent,
        filterParams: {
          field: 'TdrListingType.TdrListingTypeDisplayName'
        }
      },
      {
        headerName: 'APN', valueGetter: function (params: any) {
          return { LinkValue: params.data.Parcel.DetailUrl, LinkDisplay: params.data.Parcel.ParcelNumber };
        }, cellRendererFramework: ExternalLinkRendererComponent,
        cellRendererParams: { href: "" },
        filterValueGetter: function (params: any) {
          return params.data.Parcel.ParcelNumber;
        },
        comparator: function (id1: any, id2: any) {
          let link1 = id1.LinkDisplay;
          let link2 = id2.LinkDisplay;
          if (link1 < link2) {
            return -1;
          }
          if (link1 > link2) {
            return 1;
          }
          return 0;
        },
        sortable: true,
        width: 110
      },
      {
        headerName: 'Development Right', field: 'LandCapabilityType.Commodity.CommodityDisplayName', sortable: true, width: 250,
        filterFramework: CustomDropdownFilterComponent,
        filterParams: {
          field: 'LandCapabilityType.Commodity.CommodityDisplayName'
        }
      },
      {
        headerName: 'Land Capability', field: 'LandCapabilityType.BaileyRatingOrIpesDisplayName', sortable: true, width: 150,
        filterFramework: CustomDropdownFilterComponent,
        filterParams: {
          field: 'LandCapabilityType.BaileyRatingOrIpesDisplayName'
        }
      },
      { headerName: 'HRA', field: 'Parcel.HRA', sortable: true, filter: 'Parcel.HRA', width: 125 },
      this.createCurrencyColumnDef("Unit Price", "UnitPrice"),
      { headerName: 'Transfer Quantity', field: 'TransferQuantity', valueFormatter: params => `${params.data.TransferQuantity} ${params.data.LandCapabilityType != null ? params.data.LandCapabilityType.Commodity.CommodityUnitType.CommodityUnitTypeAbbreviation : ""}`, sortable: true, width: 125 },
      { headerName: 'Contact Email', field: 'CreatePerson.Email', sortable: true },

    ];

    this.columnDefs.forEach((x) => {
      x.resizable = true;
    });

  }

  ngOnDestroy() {
    this.cdr.detach();
  }

  public updateGridData() {
    this.tdrListingService.publishedListingsGet().subscribe((result) => {
      this.listingsGrid.api.setRowData(result);
    });
  }

  private dateFilterComparator(filterLocalDateAtMidnight, cellValue) {
    const cellDate = Date.parse(cellValue);
    if (cellDate == filterLocalDateAtMidnight) {
      return 0;
    }
    return (cellDate < filterLocalDateAtMidnight) ? -1 : 1;
  }

  private createDateColumnDef(headerName: string, fieldName: string, dateFormat: string): ColDef {
    let datePipe = new DatePipe('en-US');

    return {
      headerName: headerName, valueGetter: function (params: any) {
        return datePipe.transform(params.data[fieldName], dateFormat);
      },
      comparator: this.dateFilterComparator,
      filter: 'agDateColumnFilter',
      filterParams: {
        filterOptions: ['inRange'],
        comparator: this.dateFilterComparator
      },
      resizable: true,
      sortable: true,
      width: 125
    };
  }

  private createCurrencyColumnDef(headerName: string, fieldName: string): ColDef {
    let currencyPipe = new CurrencyPipe('en-US');
    
    return {
      headerName: headerName, valueGetter: function (params: any) {
        return currencyPipe.transform(params.data[fieldName], null, "symbol");
      },
      resizable: true,
      sortable: true,
      width: 110
    };
  } 
}

export enum CommodityEnum {
  CommercialFloorArea = 1,
  CoverageHard = 2,
  CoverageSoft = 3,
  CoveragePotential = 4,
  SingleFamilyResidentialUnitOfUse = 5,
  PersonsAtOneTime = 6,
  PotentialResidentialUnitOfUse = 7,
  ResidentialAllocation = 8,
  ResidentialBonusUnit = 9,
  RestorationCredit = 10,
  TouristAccommodationUnit = 11,
  ResidentialFloorArea = 12,
  TouristFloorArea = 13,
  MultiFamilyResidentialUnitOfUse = 14,
  MooringAllocation = 15,
  PierAllocation = 16,
  ExistingShorezoneStructure = 17
}

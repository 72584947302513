import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { merge, Observable } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { Injectable } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AlertService } from '../../services/alert.service';
import { TdrListingService } from '../../generated/api/tdr-listing.service';

@Injectable({
  providedIn: 'root'
})

export class ListingWorkflowEditAccessGuard implements CanActivate {
  constructor(
    private router: Router,
    private alertService: AlertService,
    private authenticationService: AuthenticationService,
    private listingService: TdrListingService) { }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean 
  {
    const tdrListingIDString = route.paramMap.get("tdrListingID");
    if (tdrListingIDString) {
      return this.authenticationService.currentUserSetObservable
        .pipe(
          map(currentUser => {
            var tdrListingID =  parseInt(tdrListingIDString);
            return this.listingService.listingsTdrListingIDGet(tdrListingID).pipe(map(listing => {
              if (this.authenticationService.isUserLTInfoAdministrator(currentUser) || this.authenticationService.isUserParcelTrackerAdministrator(currentUser) || listing.CreatePersonID == currentUser.PersonID) {
                return true;
              } else {
                return this.returnUnauthorized();
              }
            })
            )
          })
        ).pipe(mergeMap(x => merge(x)));

    }
    return this.returnUnauthorized();
  }

  private returnUnauthorized() {
    this.router.navigate(["/"]).then(() => {
      this.alertService.pushNotFoundUnauthorizedAlert();
    });
    return false;
  }
}

import {
  Component,
  OnInit,
  ChangeDetectorRef,
  OnDestroy,
  ViewChild,
} from "@angular/core";
import { PersonDto } from "src/app/shared/generated/model/person-dto";
import { TdrListingService } from "src/app/shared/generated/api/tdr-listing.service";
import { AuthenticationService } from "src/app/services/authentication.service";
import { DatePipe, DecimalPipe, CurrencyPipe } from "@angular/common";
import { ColDef, GridOptions } from "ag-grid-community";
import { LinkRendererComponent } from "src/app/shared/components/ag-grid/link-renderer/link-renderer.component";
import { ExternalLinkRendererComponent } from "src/app/shared/components/ag-grid/external-link-renderer/external-link-renderer.component";

import { AgGridAngular } from "ag-grid-angular";
import { UtilityFunctionsService } from "src/app/services/utility-functions.service";
import { CustomRichTextType } from 'src/app/shared/models/enums/custom-rich-text-type.enum';
import { FieldDefinitionGridHeaderComponent } from "src/app/shared/components/field-definition-grid-header/field-definition-grid-header.component";
import { CustomDropdownFilterComponent } from "src/app/shared/components/custom-dropdown-filter/custom-dropdown-filter.component";
import { FontAwesomeIconLinkRendererComponent } from "src/app/shared/components/ag-grid/fontawesome-icon-link-renderer/fontawesome-icon-link-renderer.component";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { Alert } from "src/app/shared/models/alert";
import { AlertContext } from "src/app/shared/models/enums/alert-context.enum";
import { AlertService } from "src/app/shared/services/alert.service";

@Component({
  selector: "tdrmarketplace-my-listings",
  templateUrl: "./my-listings.component.html",
  styleUrls: ["./my-listings.component.scss"],
})
export class MyListingsComponent implements OnInit, OnDestroy {
  @ViewChild("listingsGrid") listingsGrid: AgGridAngular;
  @ViewChild('deleteListingModal') deleteListingModal

  private currentPerson: PersonDto;

  public richTextTypeID: number = CustomRichTextType.MyTDRListings;
  public gridOptions: GridOptions;
  public listings = [];
  columnDefs: any;
  private modalReference: NgbModalRef;
  private listingIDToDelete: number;
  private listingToDeleteDisplay: string;
  private deleteColumnID = 1;
  private isLoadingDelete = false;

  constructor(
    private cdr: ChangeDetectorRef,
    private authenticationService: AuthenticationService,
    private utilityFunctionsService: UtilityFunctionsService,
    private alertService: AlertService,
    private tdrListingService: TdrListingService,
    private modalService: NgbModal
  ) { }

  ngOnInit() {
    this.authenticationService.getCurrentUser().subscribe((currentUser) => {
      this.currentPerson = currentUser;
      this.gridOptions = <GridOptions>{};
      this.gridOptions.overlayNoRowsTemplate = "You do not have any Listings";
      this.listingsGrid?.api.showLoadingOverlay();
      this.tdrListingService.myListingsPersonIDGet(currentUser.PersonID).subscribe((listings) => {
        this.listings = listings;
        this.listingsGrid.api.sizeColumnsToFit();
        this.listingsGrid.api.hideOverlay();
        this.cdr.detectChanges();
      });
  
      this.columnDefs = [
        {
          valueGetter: params => `${params.data.TdrListingID}/edit`,
          cellRendererFramework: FontAwesomeIconLinkRendererComponent,
          cellRendererParams: { inRouterLink: '/listings/', fontawesomeIconName: 'edit', CssClasses: 'text-primary' },
          width: 51, sortable: false, filter: false
        },
        {
          cellRendererFramework: FontAwesomeIconLinkRendererComponent,
          cellRendererParams: { isSpan: true, fontawesomeIconName: 'trash', CssClasses: 'text-danger' },
          width: 48, sortable: false, filter: false
        },
        {
          valueGetter: (params: any) => {
            return { LinkValue: params.data.TdrListingID, LinkDisplay: "View", CssClasses: "btn btn-tdrmarketplace btn-sm" };
          }, cellRendererFramework: LinkRendererComponent,
          cellRendererParams: { inRouterLink: "/listings/", },
          width: 59, sortable: false, filter: false
        },
        {
          headerName: 'Status', field: 'TdrListingStatus.TdrListingStatusDisplayName', sortable: true, width: 125,
          filterFramework: CustomDropdownFilterComponent,
          filterParams: {
            field: 'TdrListingStatus.TdrListingStatusDisplayName'
          }
        },
        this.createDateColumnDef('Posting Date', 'PostingDate', 'M/d/yyyy'),
        {
          headerName: 'Type', field: 'TdrListingType.TdrListingTypeDisplayName', sortable: true, width: 125,
          filterFramework: CustomDropdownFilterComponent,
          filterParams: {
            field: 'TdrListingType.TdrListingTypeDisplayName'
          }
        },
        {
          headerName: 'APN', valueGetter: function (params: any) {
            return { LinkValue: params.data.Parcel.DetailUrl, LinkDisplay: params.data.Parcel.ParcelNumber };
          }, cellRendererFramework: ExternalLinkRendererComponent,
          cellRendererParams: { href: "" },
          filterValueGetter: function (params: any) {
            return params.data.Parcel.ParcelNumber;
          },
          comparator: function (id1: any, id2: any) {
            let link1 = id1.LinkDisplay;
            let link2 = id2.LinkDisplay;
            if (link1 < link2) {
              return -1;
            }
            if (link1 > link2) {
              return 1;
            }
            return 0;
          },
          sortable: true,
          width: 110
        },
        {
          headerName: 'Development Right', field: 'LandCapabilityType.Commodity.CommodityDisplayName', sortable: true, width: 250,
          filterFramework: CustomDropdownFilterComponent,
          filterParams: {
            field: 'LandCapabilityType.Commodity.CommodityDisplayName'
          }
        },
        {
          headerName: 'Land Capability', field: 'LandCapabilityType.BaileyRatingOrIpesDisplayName', sortable: true, width: 150,
          filterFramework: CustomDropdownFilterComponent,
          filterParams: {
            field: 'LandCapabilityType.BaileyRatingOrIpesDisplayName'
          }
        },
        { headerName: 'HRA', field: 'Parcel.HRA', sortable: true, filter: 'Parcel.HRA', width: 125 },
        this.createCurrencyColumnDef("Unit Price", "UnitPrice"),
        { headerName: 'Transfer Quantity', field: 'TransferQuantity', valueFormatter: params => `${params.data.TransferQuantity} ${params.data.LandCapabilityType != null ? params.data.LandCapabilityType.Commodity.CommodityUnitType.CommodityUnitTypeAbbreviation : ""}`, sortable: true, width: 125 },
        { headerName: 'Contact Email', field: 'CreatePerson.Email', sortable: true },
  
      ];
  
      this.columnDefs.forEach((x) => {
        x.resizable = true;
      });
    });
    

  }

  ngOnDestroy() {
    this.cdr.detach();
  }

  public updateGridData() {
    this.tdrListingService.myListingsPersonIDGet(this.currentPerson.PersonID).subscribe((result) => {
      this.listingsGrid.api.setRowData(result);
    });
  }

  private dateFilterComparator(filterLocalDateAtMidnight, cellValue) {
    const cellDate = Date.parse(cellValue);
    if (cellDate == filterLocalDateAtMidnight) {
      return 0;
    }
    return (cellDate < filterLocalDateAtMidnight) ? -1 : 1;
  }

  private createDateColumnDef(headerName: string, fieldName: string, dateFormat: string): ColDef {
    let datePipe = new DatePipe('en-US');

    return {
      headerName: headerName, valueGetter: function (params: any) {
        return datePipe.transform(params.data[fieldName], dateFormat);
      },
      comparator: this.dateFilterComparator,
      filter: 'agDateColumnFilter',
      filterParams: {
        filterOptions: ['inRange'],
        comparator: this.dateFilterComparator
      },
      resizable: true,
      sortable: true,
      width: 125
    };
  }

  private createCurrencyColumnDef(headerName: string, fieldName: string): ColDef {
    let currencyPipe = new CurrencyPipe('en-US');

    return {
      headerName: headerName, valueGetter: function (params: any) {
        return currencyPipe.transform(params.data[fieldName], null, "symbol");
      },
      resizable: true,
      sortable: true,
      width: 110
    };
  }

  public onCellClicked(event: any): void {
    if (event.column.colId == this.deleteColumnID) {
      this.listingIDToDelete = event.data.TdrListingID;
      this.listingToDeleteDisplay =`an ${event.data.TdrListingType.TdrListingTypeDisplayName} for APN ${event.data.Parcel.ParcelNumber}` ;
      this.launchModal(this.deleteListingModal, 'deleteListingModalTitle');
    }
  }

  private launchModal(modalContent: any, modalTitle: string): void {
    this.modalReference = this.modalService.open(
      modalContent,
      {
        ariaLabelledBy: modalTitle, beforeDismiss: () => this.checkIfDeleting(), backdrop: 'static', keyboard: false
      });
  }

  private checkIfDeleting(): boolean {
    return this.isLoadingDelete;
  }

  public deleteListing() {
    this.isLoadingDelete = true;

    this.tdrListingService.listingsTdrListingIDDelete(this.listingIDToDelete).subscribe(() => {
      this.isLoadingDelete = false;
      this.modalReference.close();
      this.alertService.pushAlert(new Alert('Listing was successfully deleted.', AlertContext.Success, true));
      this.updateGridData();
    }, error => {
      this.isLoadingDelete = false;
      window.scroll(0, 0);
      this.cdr.detectChanges();
    });
  }
}

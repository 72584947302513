import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { Alert } from 'src/app/shared/models/alert';
import { AlertContext } from 'src/app/shared/models/enums/alert-context.enum';
import { AuthenticationService } from 'src/app/services/authentication.service';

import { PersonDto } from 'src/app/shared/generated/model/person-dto';
import { AlertService } from 'src/app/shared/services/alert.service';
import { PersonSimpleDto } from 'src/app/shared/generated/model/person-simple-dto';
import { CustomRichTextType } from 'src/app/shared/models/enums/custom-rich-text-type.enum';
import { TdrListingUpsertDto } from 'src/app/shared/generated/model/tdr-listing-upsert-dto';
import { TdrListingService } from 'src/app/shared/generated/api/tdr-listing.service';
import { TdrListingSimpleDto } from 'src/app/shared/generated/model/tdr-listing-simple-dto';
import { TdrListingTypeDto } from 'src/app/shared/generated/model/tdr-listing-type-dto';
import { LandCapabilityTypeSimpleDto } from 'src/app/shared/generated/model/land-capability-type-simple-dto';
import { LandCapabilityTypeService } from 'src/app/shared/generated/api/land-capability-type.service';
import { ListingWorkflowService } from 'src/app/services/listing-workflow.service';
import { ParcelService } from 'src/app/shared/generated/api/parcel.service';
import { ParcelSimpleDto } from 'src/app/shared/generated/model/parcel-simple-dto';
import { ListingStatusEnum } from 'src/app/shared/models/enums/listing-status.enum';
import { ListingTypeEnum } from 'src/app/shared/models/enums/listing-type.enum';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'tdrmarketplace-listing-offer-details',
  templateUrl: './listing-offer-details.component.html',
  styleUrls: ['./listing-offer-details.component.scss']
})
export class ListingOfferDetailsComponent implements OnInit {
  public currentUser: PersonDto;
  
  public tdrListingID: number;
  public listingModel: TdrListingUpsertDto;
  public invalidFields: Array<string> = [];
  public isLoadingSubmit = false;
  public isLoadingPublish = false;
  public introRichTextTypeID : number = CustomRichTextType.TdrListingOfferDetails;
  public developmentRightHelpRichTextTypeID : number = CustomRichTextType.DevelopmentRightHelp;
  public offerHelpRichTextTypeID : number = CustomRichTextType.OfferHelp;
  public originalListingModel: string;
  public parcel: ParcelSimpleDto;
  public landCapabilityTypes: Array<LandCapabilityTypeSimpleDto>;
  public listingTypes: Array<TdrListingTypeDto>;
  private listingStatusID: number;
  public quantityAppendText: string;


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private listingService: TdrListingService,
    private landCapabilityTypeService: LandCapabilityTypeService,
    private parcelService: ParcelService,
    private listingWorkflowService: ListingWorkflowService,


  ) { }

  canExit(){
    return this.originalListingModel == JSON.stringify(this.listingModel);
  };

  ngOnInit(): void {
    const tdrListingID = this.route.snapshot.paramMap.get("tdrListingID");
    this.authenticationService.getCurrentUser().subscribe(currentUser => {
      this.currentUser = currentUser;
      this.listingModel = new TdrListingUpsertDto();
      if (tdrListingID) {
        this.tdrListingID = parseInt(tdrListingID);
        this.listingService.listingsTdrListingIDGet(this.tdrListingID).subscribe(listing => {

          this.mapListingSimpleDtoToListingModel(listing);
          this.listingStatusID = listing.TdrListingStatusID;
          this.parcel = listing.Parcel;
          this.originalListingModel = JSON.stringify(this.listingModel);
          if (listing.ParcelID && listing.TdrListingTypeID == ListingTypeEnum.OfferToSell) {
            this.parcelService.parcelsParcelIDLandCapabilityTypesGet(listing.ParcelID).subscribe(landCapabilityTypes => {
              this.landCapabilityTypes = landCapabilityTypes;
            })
          } 
          else {
            this.landCapabilityTypeService.landCapabilityTypesGet().subscribe(landCapabilityTypes => {
              this.landCapabilityTypes = landCapabilityTypes;
            })
          }
        });

      } else {
        this.listingModel.CreatePersonID = this.currentUser.PersonID;
        this.originalListingModel = "";
        this.landCapabilityTypeService.landCapabilityTypesGet().subscribe(landCapabilityTypes => {
          this.landCapabilityTypes = landCapabilityTypes;
        })
      }


      this.listingService.listingTypesGet().subscribe(listingTypes => {
        this.listingTypes = listingTypes;
      })

      this.cdr.detectChanges();
    });
  }

  ngOnDestroy() {
    this.cdr.detach();
  }


  public getParcelDetailUrl(): string {
    return `${this.parcel?.DetailUrl}`;
}

  private mapListingSimpleDtoToListingModel(listing: TdrListingSimpleDto) {
    this.listingModel.TdrListingID = listing.TdrListingID;
    this.listingModel.TdrListingTypeID = listing.TdrListingTypeID;
    this.listingModel.ParcelID = listing.ParcelID;
    this.listingModel.ParcelNumber = listing.Parcel?.ParcelNumber;
    this.listingModel.LandCapabilityTypeID = listing.LandCapabilityTypeID;
    this.listingModel.UnitPrice = listing.UnitPrice;
    this.listingModel.TransferQuantity = listing.TransferQuantity;
    this.quantityAppendText = listing.LandCapabilityType?.Commodity?.CommodityUnitType?.CommodityUnitTypeAbbreviation;
    this.listingModel.CreatePersonID = listing.CreatePersonID;
    this.listingModel.OrganizationName = listing.OrganizationName;
    this.listingModel.PhoneNumber = listing.PhoneNumber;
    this.listingModel.AdditionalContactInformation = listing.AdditionalContactInformation;
    this.listingModel.Notes = listing.Notes;
  }

  public isFieldInvalid(fieldName: string) {
    return this.invalidFields.indexOf(fieldName) > -1;
  }

  private onSubmitSuccess(successMessage: string, tdrListingID: number, continueToListingDetails?: boolean) {
    this.isLoadingSubmit = false;
    this.isLoadingPublish = false;
    this.originalListingModel = JSON.stringify(this.listingModel);

    const rerouteURL = continueToListingDetails ? `/listings/${tdrListingID}` : `/listings/${tdrListingID}/edit/offer-details`;

    this.router.navigateByUrl(rerouteURL).then(() => {
      this.alertService.pushAlert(new Alert(successMessage, AlertContext.Success));
    });
    window.scroll(0,0);
  }

  private onSubmitFailure(error) {
    if (error.error?.errors) {
      for (let key of Object.keys(error.error.errors)) {
        this.invalidFields.push(key);
      }
    }
    this.isLoadingSubmit = false;
    this.isLoadingPublish = false;
    window.scroll(0,0);
    this.cdr.detectChanges();
  }

  public onSubmit(createProjectForm: HTMLFormElement, continueToListingDetails?: boolean): void {
    this.isLoadingSubmit = true;
    this.invalidFields = [];
    this.alertService.clearAlerts();

    if (this.tdrListingID) {
      
      this.listingService.listingsTdrListingIDOfferDetailsUpdatePut(this.tdrListingID, this.listingModel).subscribe(() => {
        this.listingWorkflowService.emitWorkflowUpdate();

        this.onSubmitSuccess("Your listing was successfully saved.", this.tdrListingID, continueToListingDetails)
      }, error => {
        this.onSubmitFailure(error);
      });
      
    } else {
      this.listingService.listingsNewPost(this.listingModel).subscribe(listing => {
        this.onSubmitSuccess("Your listing was successfully created.", listing.TdrListingID, continueToListingDetails)
      }, error => {
        this.onSubmitFailure(error);
      });
    }
  }

  
  public onPublish(): void {
    this.isLoadingPublish = true;
    this.invalidFields = [];
    this.alertService.clearAlerts();

    if (this.tdrListingID) {
      
      this.listingService.listingsTdrListingIDPublishPut(this.tdrListingID, this.listingModel).subscribe(() => {
        this.listingWorkflowService.emitWorkflowUpdate();

        this.onSubmitSuccess("Your listing was successfully published.", this.tdrListingID, true)
      }, error => {
        this.onSubmitFailure(error);
      });
      
    } else {
      this.alertService.pushAlert(new Alert("Your listing cannot be published at this time.", AlertContext.Danger));
    }
  }

  private isListingBasicsComplete(): boolean {
    let model = this.listingModel;
    return model &&
      (model.TdrListingTypeID != null && model.TdrListingTypeID != undefined) &&
      (model.ParcelID != null && model.ParcelID != undefined)
  }

  private isOfferDetailsComplete(): boolean {
    let model = this.listingModel;
    return model &&
      (model.LandCapabilityTypeID != null && model.LandCapabilityTypeID != undefined) &&
      (model.TransferQuantity != null && model.TransferQuantity != undefined) &&
      (model.UnitPrice != null && model.UnitPrice != undefined)
  }

  public showPublishButton(): boolean{
    return this.listingStatusID != ListingStatusEnum.Published;
  }

  public canPublishListing(): boolean{
    return this.isListingBasicsComplete() && this.isOfferDetailsComplete() && this.listingStatusID != ListingStatusEnum.Published;
  }

  public updateQuantityAppendText(event) {
    var selectedLandCapability = this.landCapabilityTypes.find(x => x.LandCapabilityTypeID === this.listingModel.LandCapabilityTypeID)
    this.quantityAppendText = selectedLandCapability?.Commodity?.CommodityUnitType?.CommodityUnitTypeAbbreviation;
  }

  public getTotalPrice(){
    return this.listingModel.TransferQuantity != null && this.listingModel.UnitPrice != null ? this.getCurrencyDisplay(this.listingModel.TransferQuantity * this.listingModel.UnitPrice)  : "";
  }

  private getCurrencyDisplay(currency: number): string {
    let currencyPipe = new CurrencyPipe('en-US');

    return currencyPipe.transform(currency, null, "symbol")
  }
}

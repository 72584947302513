import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { Alert } from 'src/app/shared/models/alert';
import { AlertContext } from 'src/app/shared/models/enums/alert-context.enum';
import { AuthenticationService } from 'src/app/services/authentication.service';

import { PersonDto } from 'src/app/shared/generated/model/person-dto';
import { AlertService } from 'src/app/shared/services/alert.service';
import { PersonSimpleDto } from 'src/app/shared/generated/model/person-simple-dto';
import { CustomRichTextType } from 'src/app/shared/models/enums/custom-rich-text-type.enum';
import { TdrListingUpsertDto } from 'src/app/shared/generated/model/tdr-listing-upsert-dto';
import { TdrListingService } from 'src/app/shared/generated/api/tdr-listing.service';
import { TdrListingSimpleDto } from 'src/app/shared/generated/model/tdr-listing-simple-dto';
import { TdrListingTypeDto } from 'src/app/shared/generated/model/tdr-listing-type-dto';
import { UserService } from 'src/app/shared/generated/api/user.service';
import { environment } from 'src/environments/environment';
import { ParcelDisplayDto } from 'src/app/shared/generated/model/parcel-display-dto';

@Component({
  selector: 'tdrmarketplace-listing-basics',
  templateUrl: './listing-basics.component.html',
  styleUrls: ['./listing-basics.component.scss']
})
export class ListingBasicsComponent implements OnInit {
  public currentUser: PersonDto;
  
  public tdrListingID: number;
  public listingModel: TdrListingUpsertDto;
  public createPersonDisplayName: string;
  public createPersonEmail: string;
  public invalidFields: Array<string> = [];
  public isLoadingSubmit = false;
  public introRichTextTypeID : number = CustomRichTextType.TdrListingContactAndParcelInformation;
  public contactHelpRichTextTypeID : number = CustomRichTextType.ContactInformationHelp;
  public listingHelpRichTextTypeID : number = CustomRichTextType.ListingInformationHelp;
  public originalListingModel: string;
  public listingTypes: Array<TdrListingTypeDto>;

  public selectedParcel: ParcelDisplayDto;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private listingService: TdrListingService,
    private userService: UserService

  ) { }

  canExit(){
    return this.originalListingModel == JSON.stringify(this.listingModel);
  };

  ngOnInit(): void {
    const tdrListingID = this.route.snapshot.paramMap.get("tdrListingID");
    this.authenticationService.getCurrentUser().subscribe(currentUser => {
      this.currentUser = currentUser;
      this.listingModel = new TdrListingUpsertDto();
      if (tdrListingID) {
        this.tdrListingID = parseInt(tdrListingID);
        this.listingService.listingsTdrListingIDGet(this.tdrListingID).subscribe(listing => {
          this.selectedParcel = new ParcelDisplayDto();
          this.mapListingSimpleDtoToListingModel(listing);
          this.originalListingModel = JSON.stringify(this.listingModel);

          this.createPersonDisplayName = `${listing.CreatePerson.FirstName} ${listing.CreatePerson.LastName}`;
          this.createPersonEmail = `${listing.CreatePerson.Email}`
        });
      } else {
        this.listingModel.CreatePersonID = this.currentUser.PersonID;
        this.createPersonDisplayName = `${this.currentUser.FirstName} ${this.currentUser.LastName}`;
        this.createPersonEmail = `${this.currentUser.Email}`
        this.originalListingModel = "";
        this.selectedParcel = new ParcelDisplayDto();
      }

      this.listingService.listingTypesGet().subscribe(listingTypes => {
        this.listingTypes = listingTypes;
      })

      this.cdr.detectChanges();
    });
  }

  ngOnDestroy() {
    this.cdr.detach();
  }

  private mapListingSimpleDtoToListingModel(listing: TdrListingSimpleDto) {
    this.listingModel.TdrListingID = listing.TdrListingID;
    this.listingModel.TdrListingTypeID = listing.TdrListingTypeID;
    this.listingModel.ParcelID = listing.ParcelID;
    this.listingModel.ParcelNumber = listing.Parcel?.ParcelNumber;
    this.listingModel.LandCapabilityTypeID = listing.LandCapabilityTypeID;
    this.listingModel.UnitPrice = listing.UnitPrice;
    this.listingModel.TransferQuantity = listing.TransferQuantity;
    this.listingModel.CreatePersonID = listing.CreatePersonID;
    this.listingModel.OrganizationName = listing.OrganizationName;
    this.listingModel.PhoneNumber = listing.PhoneNumber;
    this.listingModel.AdditionalContactInformation = listing.AdditionalContactInformation;
    this.listingModel.Notes = listing.Notes;

    this.selectedParcel.ParcelID = listing.ParcelID;
    this.selectedParcel.ParcelNumber = listing.Parcel?.ParcelNumber;
    var displayAddress = listing.Parcel?.ParcelStreetAddress;
    displayAddress = listing.Parcel?.ParcelCity ? displayAddress + (displayAddress ? ", " + listing.Parcel?.ParcelCity : listing.Parcel?.ParcelCity) : displayAddress;
    displayAddress = listing.Parcel?.ParcelState? displayAddress + (displayAddress  ? ", " + listing.Parcel?.ParcelState: listing.Parcel?.ParcelState) : displayAddress;
    displayAddress = listing.Parcel?.ParcelZipCode? displayAddress + (displayAddress ? " " + listing.Parcel?.ParcelZipCode: listing.Parcel?.ParcelZipCode) : displayAddress;
    this.selectedParcel.DisplayAddress = displayAddress;
  }

  private mapParcelDispayDtoToListingModel(){
    this.listingModel.ParcelID = this.selectedParcel.ParcelID;
    this.listingModel.ParcelNumber = this.selectedParcel.ParcelNumber;
  }

  public isFieldInvalid(fieldName: string) {
    return this.invalidFields.indexOf(fieldName) > -1;
  }

  private onSubmitSuccess(successMessage: string, tdrListingID: number, continueToNextStep?: boolean) {
    this.isLoadingSubmit = false;  
    this.originalListingModel = JSON.stringify(this.listingModel);

    const rerouteURL = continueToNextStep ? 
      `/listings/${tdrListingID}/edit/offer-details` : `/listings/${tdrListingID}/edit/basics`;

    this.router.navigateByUrl(rerouteURL).then(() => {
      this.alertService.pushAlert(new Alert(successMessage, AlertContext.Success));
    });
    window.scroll(0,0);
  }

  private onSubmitFailure(error) {
    if (error.error?.errors) {
      for (let key of Object.keys(error.error.errors)) {
        this.invalidFields.push(key);
      }
    }
    this.isLoadingSubmit = false;
    window.scroll(0,0);
    this.cdr.detectChanges();
  }

  public onSubmit(createProjectForm: HTMLFormElement, continueToNextStep?: boolean): void {
    this.isLoadingSubmit = true;
    this.invalidFields = [];
    this.alertService.clearAlerts();

    this.mapParcelDispayDtoToListingModel()
    if (this.tdrListingID) {
      this.listingService.listingsTdrListingIDBasicsUpdatePut(this.tdrListingID, this.listingModel).subscribe(() => {
        this.onSubmitSuccess("Your listing was successfully saved.", this.tdrListingID, continueToNextStep)
      }, error => {
        this.onSubmitFailure(error);
      });
    } else {
      this.listingService.listingsNewPost(this.listingModel).subscribe(listing => {
        this.onSubmitSuccess("Your listing was successfully created.", listing.TdrListingID, continueToNextStep)
      }, error => {
        this.onSubmitFailure(error);
      });
    }
  }

  public parcelTrackerBaseUrl(): string {
    return environment.parcelTrackerBaseUrl;
  }
}

import { Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Observable, of, Subject, forkJoin} from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators';
import { ParcelService } from 'src/app/shared/generated/api/parcel.service';
import { ParcelDisplayDto } from 'src/app/shared/generated/model/parcel-display-dto';
import * as ParseAddress from 'src/assets/main/parse-address/parse-address.min.js';

@Component({
  selector: 'parcel-typeahead',
  templateUrl: './parcel-typeahead.component.html',
  styleUrls: ['./parcel-typeahead.component.scss']
})
export class ParcelTypeaheadComponent implements OnInit, OnChanges {
  @ViewChild(NgSelectComponent) ngSelectComponent: NgSelectComponent;
  @Input() selectedParcel: ParcelDisplayDto;

  public parcels$: Observable<ParcelDisplayDto[]>;
  public parcelInputs$ = new Subject<string>();
  public searchLoading = false;

  constructor(
    private parcelService: ParcelService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if(Object.keys(changes)){
      this.initSearch();
    }
  }

  ngOnInit(): void {
    this.initSearch();
  }

  initSearch(): void {
    this.parcels$ = this.parcelInputs$.pipe(
      filter(searchTerm => searchTerm != null),
      distinctUntilChanged(),
      tap(() => this.searchLoading = true),
      debounceTime(800),
      switchMap(
        searchTerm => 
        this.searchParcelNumberAndAddress(searchTerm).pipe(
          catchError(() => of([])),
          tap(() => this.searchLoading = false)
         )
      )
    );
  }

  private searchParcelNumberAndAddress(searchTerm: string): Observable<ParcelDisplayDto[]>{
    var parsedTerms = this.parseSearchTerm(searchTerm);
    return this.parcelService.parcelsSearchGet(searchTerm, parsedTerms[0], parsedTerms[1])
  }

  private parseSearchTerm(query: string) : Array<string>{
      var parsed = ParseAddress.parseLocation(query);
      var reassembled = '';
      var spacerOrComma = '';
      for (var key in parsed) {
          if (parsed.hasOwnProperty(key)) {
              if (reassembled.length > 0)
              {
                  spacerOrComma = key === 'city' || key === 'state' ? ', ' : ' ';
              }
              reassembled = reassembled + spacerOrComma + parsed[key];
          }
      }

      // for directions, we should check the abbreviation and the full word
      var directionLookup = {
          'N': 'North',
          'S': 'South',
          'E': 'East',
          'W': 'West',
          'NE': 'Northeast',
          'NW': 'Northwest',
          'SE': 'Southeast',
          'SW': 'Southwest'
      };

      var directionLookupReverse = {
          'north': 'N',
          'south': 'S',
          'east': 'E',
          'west': 'W',
          'northeast': 'NE',
          'northwest': 'NW',
          'southeast': 'SE',
          'southwest': 'SW'
      };

      // if the 'street' property is a cardinal direction (e.g. "North") and there is no 'prefix' or 'type', the address may be a partial in such a way that the intent of the "North" word is that is a prefix for a street that has yet to be typed
      // in this case, we should search for the abbreviation as well.
      var streetAbbreviated = '';
      if (parsed.hasOwnProperty('street') && !parsed.hasOwnProperty('prefix') && !parsed.hasOwnProperty('type') && parsed.street.toLowerCase() in directionLookupReverse)
      {
          parsed.street = directionLookupReverse[parsed.street.toLowerCase()];
          spacerOrComma = '';
          for (var key in parsed) {
              if (parsed.hasOwnProperty(key)) {
                  if (streetAbbreviated.length > 0) {
                      spacerOrComma = key === 'city' || key === 'state' ? ', ' : ' ';
                  }
                  streetAbbreviated = streetAbbreviated + spacerOrComma + parsed[key];
              }
          }
      }

      var directionFull = '';
      if (parsed.hasOwnProperty('prefix') || parsed.hasOwnProperty('suffix'))
      {
          if (parsed.prefix != undefined) {
              parsed.prefix = directionLookup[parsed.prefix.toUpperCase()];
          }
          if (parsed.suffix != undefined) {
              parsed.suffix = directionLookup[parsed.suffix.toUpperCase()];
          }
          spacerOrComma = '';
          for (var key in parsed) {
              if (parsed.hasOwnProperty(key)) {
                  if (directionFull.length > 0) {
                      spacerOrComma = key === 'city' || key === 'state' ? ', ' : ' ';
                  }
                  directionFull = directionFull + spacerOrComma + parsed[key];
              }
          }
      }

      var parsedOther = streetAbbreviated.length > 0 ? streetAbbreviated : directionFull;
      return [reassembled, parsedOther];
  }

  public onSelectedParcelChange(parcel: ParcelDisplayDto) {
    this.selectedParcel.ParcelID = parcel ? parcel.ParcelID : null;
    this.selectedParcel.ParcelNumber = parcel ? parcel.ParcelNumber : null;
  }
}

<h2 class="d-inline-block">
    Listing Instructions
</h2>
<custom-rich-text [customRichTextTypeID]="customRichTextTypeID" id="instructionsRichText"></custom-rich-text>

<div *ngIf="!isEditingRichText()" class="row m-1 mt-3">
    <div class="col-auto ml-auto">
        <button class="btn btn-tdrmarketplace ml-auto" (click)="continueToNextStep()">
            Continue
        </button>

        <a *ngIf="!tdrListingID" class="btn btn-secondary ml-1" routerLink="/my-listings">Cancel</a>
        <a *ngIf="tdrListingID" class="btn btn-secondary ml-1" routerLink="../../">Cancel</a>
    </div>
</div>
export enum CustomRichTextType{
    Homepage = 215,
    About = 216,
    Training = 217,
    TDRListingList = 218,
    MyTDRListings = 219,
    TdrListingInstructions = 220,
    TdrListingContactAndParcelInformation = 221,
    TdrListingOfferDetails = 222,
    ManageTDRListings = 223,
    ContactInformationHelp = 225,
    ListingInformationHelp =226,
    DevelopmentRightHelp = 227,
    OfferHelp = 228,
}
<nav class="navbar navbar-expand-xl justify-content-start navbar-tdrmarketplace navbar-dark navbar-responsive-header">

    <a routerLink="/" routerLinkActive="active" class="navbar-brand nav-link">
        {{platformShortName()}}
    </a>
    <button class="navbar-toggler ml-auto" type="button" data-toggle="collapse" data-target="#navbar2"
        (click)="toggleCurrentPageHeader()">
        <span class="navbar-toggler-icon"></span>
    </button>
</nav>

<nav class="navbar navbar-expand-xl justify-content-start navbar-tdrmarketplace-secondary mb-2">
    <a href="{{lakeTahoeInfoMainUrl()}}" class="navbar-brand navbar-logo pt-0" target="_blank">
        <img src="assets/main/logos/laketahoeinfo-logo-blue.png" class="navbarLogo" alt="Lake Tahoe Info logo">
    </a>
    <div class="btn-group">
        <a routerLink="/" routerLinkActive="active" class=" nav-link">
            {{platformShortName()}}
        </a>
        <a href="#" class="nav-link dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
            <a href="{{lakeTahoeInfoMainUrl()}}" class="dropdown-item">
                LT Info Home
            </a>
            <div class="dropdown-divider"></div>
            <a href="{{climateDashboardBaseUrl()}}" class="dropdown-item">
                Climate Resilience Dashboard
            </a>
            <a href="{{eipProjectTrackerBaseUrl()}}" class="dropdown-item">
                EIP Project Tracker
            </a>
            <a  href="{{lakeClarityTrackerBaseUrl()}}" class="dropdown-item">
                Lake Clarity Tracker
            </a>
            <a href="{{monitoringDashboardBaseUrl()}}" class="dropdown-item">
                Monitoring Dashboard
            </a>
            <a href="{{parcelTrackerBaseUrl()}}" class="dropdown-item">
                Parcel Tracker
            </a>
            <a  href="{{thresholdDashboardBaseUrl()}}" class="dropdown-item">
                Threshold Dashboard
            </a>
            <a  href="{{transportationTrackerBaseUrl()}}" class="dropdown-item">
                Transportation Tracker
            </a>
            <div class="dropdown-divider"></div>
            <a  href="{{fullProjectListUrl()}}" class="dropdown-item">
                Projects
            </a>
            <a  href="{{dataCenterUrl()}}" class="dropdown-item">
                Data Center
            </a>
            <a  href="{{newsAndAnnouncementsUrl()}}" class="dropdown-item">
                News & Announcements
            </a>
        </div>
    </div>


    <div class="flex-row navbar-collapse collapse" id="navbar2" style="justify-content: flex-end;">
        <ul class="navbar-nav mt-lg-0 mr-sm-2 ml-3">
            <li class="nav-item" routerLinkActive="active" *ngIf="!isAuthenticated()">
                <a href="javascript:void(0);" (click)="login()" routerLinkActive="active" class="nav-link">Log In</a>
            </li>
            <li class="nav-item" routerLinkActive="active" *ngIf="!isAuthenticated()">
                <a routerLink="/request-support" routerLinkActive="active"  class="nav-link">Request Support</a>
            </li>
            <li class="nav-item dropdown welcomeUser" routerLinkActive="active" *ngIf="isAuthenticated()">
                <a href="#" class="nav-link dropdown-toggle pr-3" role="button" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">
                    Welcome {{getUserName()}}
                </a>
                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                    <a routerLink="/request-support"  class="dropdown-item">Request Support</a>
                    <a href="javascript:void(0);" (click)="logout()" class="dropdown-item">Log Out</a>
                </div>
            </li>

            <li class="nav-item dropdown" routerLinkActive="active" *ngIf="isLTInfoAdministrator() || isParcelTrackerAdministrator()">
                <a href="#" class="nav-link dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">
                    Manage LT Info
                </a>
                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                    <a *ngIf="isLTInfoAdministrator()" href="{{pageContentIndexUrl()}}" class="dropdown-item">
                        Page Content
                    </a>
                    <a *ngIf="isLTInfoAdministrator()" href="{{fieldDefinitionIndexUrl()}}" class="dropdown-item">
                        Labels and Definitions
                    </a>
                    <div *ngIf="isLTInfoAdministrator()" class="dropdown-divider"></div>
                    <a href="{{usersListUrl()}}" class="dropdown-item">
                        Users
                    </a>
                    <a href="{{roleIndexUrl()}}" class="dropdown-item">
                        Roles
                    </a>
                    <div *ngIf="isLTInfoAdministrator()" class="dropdown-divider"></div>
                    <a *ngIf="isLTInfoAdministrator()" href="{{projectUpdateManageUrl()}}" class="dropdown-item">
                        Project Updates
                    </a>
                    
                </div>
            </li>
        </ul>
    </div>

</nav>


<nav class="navbar navbar-expand-xl navbar-tdrmarketplace">
    <div class="collapse navbar-collapse" id="navbar2">
        <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
            <li class="nav-item" routerLinkActive="active">
                <a routerLink="/about" class="nav-link">
                    About
                </a>
            </li>
            <li class="nav-item dropdown" routerLinkActive="active">
                <a href="#" class="nav-link dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Listings
                </a>

                <div  class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <a routerLink="/listings" class="dropdown-item">
                        All Listings
                    </a>
                    <a *ngIf="isAuthenticated()" routerLink="/my-listings" class="dropdown-item">
                        My Listings
                    </a>
                    <a routerLink="/conversion-calculator" class="dropdown-item">
                        Conversion Calculator
                    </a>
                </div>

            </li>
            <li *ngIf="isLTInfoAdministrator() || isParcelTrackerAdministrator()" class="nav-item dropdown" routerLinkActive="active">
                <a href="#" class="nav-link dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Manage
                </a>

                <div  class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <a routerLink="/manage-listings" class="dropdown-item">
                        Manage Listings
                    </a>
                </div>

            </li>
        </ul>

    </div>
</nav>
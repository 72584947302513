export enum FieldDefinitionEnum {
    EIPActionPriority = 1,
    EIPActionPriorityName = 2,
    LocalAndRegionalPlanDisplayName = 3,
    ExpectedValue = 4,
    EIPFocusArea = 5,
    EIPFocusAreaName = 6,
    Funder = 7,
    FundingSource = 8,
    FundingSourceDescription = 9,
    FundingSourceName = 10,
    Implementer = 11,
    LeadImplementer = 12,
    OldEIPNumber = 13,
    Organization = 14,
    OrganizationAbbreviation = 15,
    OrganizationName = 16,
    Password = 17,
    EIPPerformanceMeasure = 18,
    IndicatorDefinition = 19,
    IndicatorDisplayName = 20,
    MeasurementUnit = 21,
    PhotoCaption = 22,
    PhotoCredit = 23,
    PhotoTiming = 24,
    PrimaryContact = 25,
    EIPProgram = 26,
    EIPProgramName = 27,
    CompletionYear = 28,
    ProjectDescription = 29,
    ProjectName = 30,
    ProjectNote = 31,
    ImplementationStartYear = 32,
    ReportedValue = 33,
    Sector = 34,
    SecuredFunding = 35,
    Stage = 36,
    Subcategories = 37,
    IndicatorDataSourceType = 38,
    ThresholdCategoryName = 39,
    EstimatedTotalCost = 40,
    UnfundedNeed = 41,
    Username = 42,
    WatershedName = 43,
    Project = 44,
    ProjectNumber = 45,
    ThresholdCategory = 46,
    LocalAndRegionalPlan = 47,
    Watershed = 48,
    Subcategory = 49,
    SubcategoryOption = 50,
    SubcategoryOptions = 51,
    IsPrimaryEIPProgram = 52,
    IndicatorCriticalDefinitions = 53,
    IndicatorAccountingPeriodAndScale = 54,
    IndicatorProjectReporting = 55,
    FundedAmount = 56,
    ProjectLocation = 57,
    IndicatorBackground = 58,
    NumberOfReportedPMRecords = 59,
    NumberOfReportedExpenditureRecords = 60,
    ProjectLocationState = 61,
    ProjectLocationJurisdiction = 62,
    ProjectLocationWatershed = 63,
    ExcludeFromFactSheet = 64,
    TransportationStrategy = 65,
    TransportationObjective = 66,
    TransportationStrategyName = 67,
    TransportationObjectiveName = 68,
    ProjectIsATransportationProject = 69,
    IsTransportationFundingSource = 72,
    FundingType = 73,
    ProjectCostInYearOfExpenditure = 74,
    TransportationGlobalInflationRate = 75,
    ReportingYear = 76,
    TagName = 77,
    TagDescription = 78,
    Tags = 79,
    ReportedExpenditure = 80,
    Proposal = 81,
    ProjectOnFTIPList = 82,
    LocalAndRegionalPlanDocumentUrl = 83,
    LocalAndRegionalPlanDocumentLinkText = 84,
    SpendingAssociatedWithPM = 85,
    PlanningDesignStartYear = 86,
    AssociatedPrograms = 87,
    ExternalLinks = 88,
    EstimatedAnnualOperatingCost = 89,
    CalculatedTotalRemainingOperatingCost = 90,
    CurrentRTPYearForPVCalculations = 91,
    LifecycleOperatingCost = 92,
    IndicatorSystemName = 93,
    IndicatorSimpleDescription = 94,
    IndicatorPrimarySource = 95,
    IndicatorType = 96,
    ChartTitle = 97,
    ProjectApprovalStatus = 98,
    FileOrCaseNumber = 99,
    AllocatedQuantity = 100,
    AllocationBonusPool = 101,
    AllocationPool = 102,
    APNWithDashes = 103,
    LocalPlan = 104,
    BaileyRating = 105,
    BankedDevelopmentRightNotes = 106,
    BankedDate = 107,
    BankedQuantity = 108,
    BMPStatus = 109,
    BonusUnits = 110,
    Comments = 111,
    DevelopmentRight = 112,
    DevelopmentRightConfirmationNotes = 113,
    DevelopmentRightConvertedTo = 114,
    DevelopmentRightPoolDisbursementAmount = 115,
    DevelopmentRightPoolDisbursementDate = 116,
    DevelopmentRightPoolDisbursementDescription = 117,
    DevelopmentRightPoolDisbursementTitle = 118,
    DevelopmentRightPoolName = 120,
    CommunityPlan = 121,
    RemainingBankedQuantity = 122,
    DeallocatedDate = 123,
    DeallocatedQuantity = 124,
    DeallocatedRationale = 125,
    DeterminationDate = 126,
    DeterminationType = 127,
    FireDistrict = 128,
    HRA = 129,
    IPESScore = 130,
    Jurisdiction = 131,
    JurisdictionProjectNumber = 132,
    LandBank = 133,
    OwnerLandBank = 134,
    LandCapability = 135,
    LandCapabilityNotes = 136,
    LandCapabilityType = 137,
    LeadAgency = 138,
    LeadAgencyAbbreviation = 139,
    OwnerName = 140,
    ParcelAddress = 141,
    ParcelNickname = 143,
    ParcelPublicNotes = 144,
    ParcelSize = 145,
    ParcelWatershed = 146,
    PrivateOrPublic = 147,
    ProjectApprovalDate = 148,
    ReceivingBaileyRating = 149,
    ReceivingIPESScore = 150,
    ReceivingJurisdiction = 151,
    ReceivingParcelAPN = 152,
    ReceivingQuantity = 153,
    ResidentialAllocationFeeReceived = 154,
    ResidentialAllocationNumber = 155,
    RetiredSensitiveParcel = 156,
    ECMRetirementApprovalDate = 157,
    ECMRetirementQuantity = 158,
    RightOfWayCoverageAmount = 159,
    RightOfWayCoverageEffectiveDate = 160,
    RightOfWayCoverageNotes = 161,
    SendingBaileyRating = 162,
    SendingIPESScore = 163,
    SendingJurisdiction = 164,
    SendingParcelAPN = 165,
    SendingQuantity = 166,
    TransactionNumber = 167,
    TransactionStatus = 168,
    TransactionType = 169,
    TransferApprovalDate = 170,
    TransferPrice = 171,
    VerifiedParcelSize = 172,
    UserLastName = 173,
    UserFirstName = 174,
    UserEmail = 175,
    EIPRoleID = 177,
    ParcelTrackerRoleID = 178,
    LTInfoRoleID = 180,
    SiteArea = 181,
    RoleName = 182,
    AccelaCAPRecordKeys = 183,
    Region = 184,
    RetiredQuantity = 187,
    ResidentialAllocationType = 189,
    ResidentialAllocationSequence = 190,
    ResidentialAllocationStatus = 191,
    ResidentialAllocationPreviousTransactions = 192,
    ResidentialAllocationAssignedToJurisdiction = 193,
    ResidentialAllocationAllocatedButNoRecord = 194,
    ParcelInitialBankedInventoryCount = 195,
    ParcelPreviousTransactionCount = 196,
    ParcelPendingTransactionCount = 197,
    ParcelAvailableToTransferInventoryCount = 198,
    VerifiedPhysicalInventory = 199,
    ParcelCommodityInventoryVerificationDate = 200,
    ParcelStatus = 201,
    ParcelFind = 202,
    CasesApplicationsAndPermits = 203,
    ThresholdReportingCategory = 204,
    ApplicableStandard = 205,
    StandardNarrative = 206,
    TRPAIndicator = 207,
    ThresholdEvaluationStatus = 208,
    ThresholdEvaluationTrend = 209,
    ThresholdEvaluationConfidence = 210,
    ThresholdEvaluationStatusRationale = 211,
    ThresholdEvaluationTrendRationale = 212,
    ThresholdEvaluationConfidenceStatus = 213,
    ThresholdEvaluationManagementStatus = 214,
    ThresholdEvaluationProgramsAndActionsImplementedToImproveConditions = 215,
    ThresholdRoleID = 216,
    ThresholdEvaluationConfidenceTrend = 217,
    ThresholdEvaluationConfidenceOverall = 218,
    ThresholdEvaluationEffectivenessOfProgramsAndActions = 219,
    ThresholdEvaluationInterimTarget = 220,
    ThresholdEvaluationTargetAttainmentDate = 221,
    ThresholdEvaluationAnalyticApproach = 222,
    ThresholdEvaluationMonitoringApproach = 223,
    ThresholdEvaluationModificationOfTheThresholdStandardOrIndicator = 224,
    ThresholdEvaluationAttainOrMaintainThreshold = 225,
    ThresholdEvaluationMonitoringAndAnalysis = 226,
    ThresholdReportingCategoryNarrative = 227,
    ChartCaption = 228,
    ParcelAddressSearch = 229,
    ParcelBaseAllowableInventoryCount = 231,
    ParcelExistingInventory = 232,
    Relevance = 233,
    HumanAndEnvironmentalDrivers = 234,
    ThresholdStandardType = 235,
    MonitoringProgram = 236,
    MonitoringApproach = 237,
    MonitoringProgramPartner = 238,
    MonitoringProgramUrl = 239,
    ThresholdCategoryDescription = 240,
    ThresholdCategoryGoalStatement = 241,
    ThresholdCategoryNarrative = 242,
    EIPProjectAddedDate = 243,
    StormwaterJurisdiction = 244,
    ModeledCatchment = 245,
    ParcelAllowableBasePercent = 246,
    ParcelAllowableBaseLandCoverage = 247,
    ParcelIPESTotalAllowableCoverage = 248,
    TreatmentBMP = 249,
    ParcelPermitNumber = 250,
    ParcelPermitProjectDescription = 251,
    ParcelPermitApplicationReceivedDate = 252,
    ParcelPermitIssuedDate = 253,
    ParcelPermitAcknowledgedDate = 254,
    ParcelPermitPreGradeInspectionDate = 255,
    ParcelPermitFinalInspectionDate = 256,
    ParcelPermitSecurityReleasedDate = 257,
    ParcelPermitNotes = 258,
    ParcelPermitType = 259,
    TreatmentBMPType = 260,
    ExistingOffsiteCoverage = 261,
    ParcelProposedOffsiteCoverage = 262,
    RegistrationStatus = 263,
    StormwaterRoleID = 264,
    ParcelExcessCoverage = 265,
    ParcelMitigatedExcessCoverage = 266,
    PerviousCoverage = 267,
    PerviousDeckCoverage = 268,
    ShedCoverage = 269,
    ADACoverage = 270,
    ParcelPermitProposedCoverageQuantity = 271,
    ParcelPermitRequiresDeedRestriction = 272,
    ParcelPermitDeedRestrictionNotes = 273,
    ParcelPermitDeedRestrictionRecordingNumber = 274,
    ParcelPermitApplicationCompleteDate = 275,
    ParcelPermitHasViolation = 276,
    ParcelPermitViolationType = 277,
    ParcelPermitIsRevoked = 278,
    ParcelPermitHasWaiver = 279,
    ParcelPermitViolationNoticeDate = 280,
    ParcelPermitViolationStopWorkOrderPostedDate = 281,
    ParcelPermitViolationCorrectionCompletedDate = 282,
    ParcelPermitDeedRestrictionRecordedDate = 283,
    RegularOrValidationAssessment = 284,
    ConveyanceFunctionsAsIntended = 285,
    AssessmentScoreWeight = 286,
    ObservationScore = 287,
    AlternativeScore = 288,
    AssessmentForInternalUseOnly = 289,
    RegistrationType = 290,
    AnnualCreditPotential = 291,
    TreatmentBMPDesignDepth = 292,
    ProposedCoverageType = 293,
    ProposedCoverageSource = 294,
    ProposedDevelopmentRightType = 295,
    ProposedDevelopmentRightSource = 296,
    ParcelPermitSectionStatus = 297,
    ParcelPermitApplicationFee = 298,
    ParcelPermitWaterQualityMitigationFee = 299,
    ParcelPermitAirQualityMitigationFee = 300,
    ParcelPermitExcessCoverageMitigationFee = 301,
    ParcelPermitOffsiteCoverageMitigationFee = 302,
    ParcelPermitSecurityAmount = 303,
    ParcelPermitExpirationDate = 304,
    ParcelPermitExcessCoverageMitigationQuantity = 305,
    BaselineScenario = 306,
    ExpectedScenario = 307,
    FineSedimentParticleLoadReduction = 308,
    TotalNitrogenLoadReduction = 309,
    TotalPhosphorusLoadReduction = 310,
    FineSedimentParticleMultiplier = 311,
    NitrogenMultiplier = 312,
    PhosphorusMultiplier = 313,
    Credits = 314,
    WaterQualityImportance = 315,
    MaintenancePlanSummary = 316,
    InspectionPlanSummary = 317,
    AdditionalImplementationInformation = 318,
    CatchmentConnectivity = 319,
    EstablishmentDate = 320,
    RegistrationDuration = 321,
    CreditDurationRationale = 322,
    CreditPotential = 323,
    RoadOperationsScenario = 324,
    BaselineLoad = 325,
    RoadOperationsLoad = 326,
    ExpectedLoad = 327,
    RoadOperationsPlan = 328,
    RegistrationMemo = 329,
    WaterQualityImportanceRationale = 330,
    Pre2004BMP = 331,
    StandardBaseline = 332,
    RoadClass = 333,
    TransitOperationInflationRate = 334,
    ExpectedConditionScore = 335,
    ImperviousArea = 336,
    NumberOfRoadSegmentsNeededToMeetAssessmentRequirement = 337,
    HeavyAccumulationZone = 338,
    ModerateAccumulationZone = 339,
    LightAccumulationZone = 340,
    CoverageOfRoadSegment = 341,
    VolumeOfMaterial = 342,
    DustCloudHeight = 343,
    DustCloudDuration = 344,
    FingerprintVisibility = 345,
    SedimentFeel = 346,
    MassOfMaterial = 347,
    DegreeOfFineMaterial = 348,
    FSPConcentration = 349,
    MonitoringRoleID = 350,
    TransportationTrackerRoleID = 351,
    ChartLastUpdatedDate = 352,
    DeedRestrictions = 354,
    ThresholdEvaluationMethods = 356,
    DeedRestrictionRecordingNumber = 357,
    ThresholdEvaluationManagementStatusRationale = 358,
    MonitoringSiteName = 359,
    MonitoringSiteStatus = 360,
    MonitoringProgramAssociatedIndicators = 364,
    ReceivesSystemCommunications = 365,
    AverageDailyFlowandCumulativePrecipitationChart = 366,
    MonthlyFlowandFineSedimentParticleLoadChart = 367,
    MonthlyFlowandTotalNitrogenLoadChart = 368,
    MonthlyFlowandTotalPhosphorusLoadChart = 369,
    StationSummaryStatisticsChart = 370,
    ProjectLastModificationDate = 371,
    NewsandAnnouncementSticky = 372,
    BankedCoverageType = 373,
    BankedDevelopmentRight = 374,
    IndicatorShortName = 375,
    AverageOfAllObservedScores = 376,
    RoadClassScore = 377,
    InspectionSeason = 378,
    ActualNumberOfRoadSegmentsThatMeetAssessmentRequirement = 379,
    AchievingExpectedConditions = 380,
    AlternativeSegmentRationale = 381,
    CreditTarget = 382,
    CreditsSecured = 383,
    TransportationGoal = 384,
    SendingStormwaterJurisdiction = 385,
    ReceivingStormwaterJurisdiction = 386,
    FspReductionTarget = 387,
    TnReductionTarget = 388,
    TpReductionTarget = 389,
    ProjectIsAnEIPProject = 390,
    SwapChartAxes = 391,
    Summable = 392,
    TargetedFunding = 393,
    IpesScoreType = 394,
    HistoricOrImportedIpesScore = 395,
    IpesParcelAreaFactor = 396,
    IpesOneHundredPercentSEZ = 397,
    BuildingSiteNotInSEZ = 398,
    ParcelAreaInSEZ = 399,
    OutsideOfBuildingSite = 400,
    ParcelAreaInSEZSetback = 401,
    LimitedIncentivePoints = 402,
    IpesCoverageScore = 403,
    IpesBuildingSiteArea = 404,
    ParcelAreaNotCoveredByIPES = 405,
    ParcelHasDeterminationOfAllowableCoverage = 406,
    NRAPFocusArea = 407,
    NRAPFinding = 408,
    NRAPStateOfKnowledge = 409,
    ProximityToLakeTahoe = 410,
    LocationofSEZ = 411,
    VegetativeGroup = 412,
    AverageBuildingSiteSlope = 413,
    ElevationOfParcel = 414,
    CompassDegreesOfParcel = 415,
    SlopeTypeDescription = 416,
    SoilErodibilityFactor = 417,
    RainfallFactor = 418,
    HydrologicalCondition = 419,
    HydrologicalSoilGroup = 420,
    SoilSymbolMapped = 421,
    SoilSymbolObserved = 422,
    SoilCategoryDescription = 423,
    InfiltrationDescription = 424,
    LengthSlopeSegments = 425,
    SlopeLengthAndGradientFactor = 426,
    UtilityDisturbance = 427,
    AccessDisturbance = 428,
    ParcelAccessType = 429,
    ExtentOfGradingRequired = 430,
    ExcavationForParking = 431,
    FeetOfExcavationGreaterThanThreeFeet = 432,
    HeightOfCutSlope = 433,
    GradientAboveCutSlope = 434,
    DegreeOfDifficultyForExcavation = 435,
    HeightOfFillSlope = 436,
    GradientBelowFillSlope = 437,
    NewAccessInStreamzone = 438,
    RelativeErosionHazardScore = 439,
    RunoffPotentialScore = 440,
    AccessScore = 441,
    UtilityInSEZScore = 442,
    ConditionOfWatershedScore = 443,
    AbilityToRevegetateScore = 444,
    WaterQualityImprovementsScore = 445,
    ProximityToLakeScore = 446,
    SEZPropertyType = 447,
    TreatmentBMPTypeForAssessment = 448,
    CompassAspectOfParcel = 449,
    ParcelLandCapabilityEstimatedOrVerified = 450,
    MonitoringProgramShortName = 451,
    MonitoringProgramVanityUrl = 452,
    IpesScoreInternalNotes = 453,
    IpesScorePublicNotes = 454,
    MonitoringProgramOrganizationRelationshipLabel = 455,
    MonitoringProgramSiteLabel = 456,
    ShowAnnouncementOnLTInfoHomepage = 457,
    BMPComplianceStatusBMPCertificateIssued = 458,
    BMPComplianceStatusSourceControlCertificateIssued = 459,
    BMPComplianceStatusAreaWideTreatmentParticipant = 460,
    BMPComplianceStatusSpecialCircumstances = 461,
    BMPComplianceStatusInfiltrationRateKSat = 462,
    LakeClarityTrackerRoleID = 463,
    TreatmentBMPArchiveDate = 465,
    TreatmentBMPEffectiveDate = 466,
    ArchivedTreatmentBMPVersion = 467,
    UnknownLandUse = 468,
    LandUseTotalArea = 469,
    LandUseWeightedPercentCertified = 470,
    ParcelBMPAssessmentDate = 471,
    PlrmSummaryReportBMPCreditPotential = 472,
    ProjectIsALakeClarityProject = 473,
    UrbanStormwaterQuestionnaireScore = 474,
    MonitoringProgramCategory = 475,
    TMDLPollutantSourceCategory = 476,
    ParcelInternalNotes = 477,
    CaliforniaStormwaterResourcePlan = 478,
    OverrideCalculatedScore = 479,
    MonitoringProgramType = 480,
    MonitoringProgramFeaturedProgram = 481,
    IsBmpCompliant = 482,
    HasTrpaPermit = 483,
    HasStatePermit = 484,
    CanBeChartedCumulatively = 485,
    RetiredFromDevelopment = 486,
    TreatmentBMPObservationType = 487,
    ObservationCollectionMethod = 488,
    ObservationThresholdType = 489,
    ObservationTargetType = 490,
    MeasurementUnitLabel = 491,
    PropertiesToObserve = 492,
    MinimumNumberOfObservations = 493,
    MaximumNumberOfObservations = 494,
    MinimumValueOfEachObservation = 495,
    MaximumValueOfEachObservation = 496,
    DefaultThresholdValue = 497,
    DefaultBenchmarkValue = 498,
    AssessmentFailsIfObservationFails = 499,
    RateMeasurementUnitLabel = 500,
    TimeMeasurementUnitLabel = 501,
    ReadingMeasurementUnitLabel = 502,
    MinimumNumberOfRatePairs = 503,
    MaximumNumberOfRatePairs = 504,
    MinimumValueOfEachObservationTime = 505,
    MaximumValueOfEachObservationTime = 506,
    MinimumValueOfEachObservationReading = 507,
    MaximumValueOfEachObservationReading = 508,
    NumberOfObservationPeriods = 509,
    PlrmModeledAsTreatmentBMPType = 510,
    ScoreOldMethod = 511,
    ScoreNewMethod = 512,
    RoadAssessmentStandardDeviation = 513,
    RoadAssessmentCalculatedScore = 514,
    ObservationTypeMeasurementUnit = 515,
    ObservationTypeRateDisplayMeasurementUnit = 516,
    ObservationTypeTimeMeasurementUnit = 517,
    ObservationTypeReadingMeasurementUnit = 518,
    AlternativeObservationType = 519,
    ThresholdEvaluationCurrentAnalyticApproach = 520,
    PreviousAPN = 521,
    NewAPN = 522,
    BMPComplianceStatusBMPRetrofitOnly = 523,
    PierAssociatedWithAnotherParcel = 524,
    CoverageTotal = 525,
    NumberOfAdditionalMooringsRequested = 526,
    ShorezoneAllocationNumber = 527,
    ShorezoneAllocationType = 528,
    ShorezoneAllocationStatus = 529,
    ShorezoneAllocationPreviousTransaction = 530,
    ProjectPrimaryContact = 531,
    RoadZoneNumberOfPaces = 532,
    HighwayZoneNumberOfPaces = 533,
    ZoneNickname = 534,
    OrganizationIsActive = 535,
    OrganizationIsApproved = 536,
    NumberOfAdditionalMooringsAllocated = 537,
    TransactionExpirationDate = 538,
    TransactionApprovalDate = 539,
    ResidentialUse = 540,
    BMPComplianceStatusCertificateRequired = 541,
    MooringRegistrationStartYear = 542,
    LandBankAcquisitionRemainingQuantity = 543,
    ThresholdIndicatorUpdateDetails = 544,
    ThresholdIndicatorUpdateBatchType = 545,
    ThresholdIndicatorUpdateDateSaved = 546,
    ThresholdIndicatorUpdatedBy = 547,
    ThresholdIndicatorUpdateEvaluationPeriod = 548,
    UnusedAllocationNumbers = 549,
    AssociatedMooringRegistrations = 550,
    RemainingBankedQuantityAdjustment = 551
}
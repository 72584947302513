export enum ParcelTrackerRoleEnum {
  Admin = 1,
  Power = 2,
  Normal = 3,
  ReadOnly = 4,
  MOUPartner = 6,
  Unassigned = 7,
  StateLands = 8,
  ParcelPermitConsultant = 9
}

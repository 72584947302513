import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotFoundComponent, UnauthenticatedComponent, SubscriptionInsufficientComponent } from './shared/pages';
import { UnauthenticatedAccessGuard } from './shared/guards/unauthenticated-access/unauthenticated-access.guard';
import { ManagerOnlyGuard } from "./shared/guards/unauthenticated-access/manager-only-guard";
import { ListingWorkflowEditAccessGuard } from "./shared/guards/unauthenticated-access/listing-workflow-edit-access-guard";
import { HomeIndexComponent } from './pages/home-index/home-index.component';
import { LoginCallbackComponent } from './pages/login-callback/login-callback.component';
import { CreateUserCallbackComponent } from './pages/create-user-callback/create-user-callback.component';
import { TrainingComponent } from './pages/training/training.component';
import { AboutComponent } from './pages/about/about.component';
import { HelpComponent } from './pages/help/help.component';
import { UnsavedChangesGuard } from './shared/guards/unsaved-changes.guard';
import { ListingListComponent } from './pages/listing-list/listing-list.component';
import { MyListingsComponent } from './pages/my-listings/my-listings.component';
import { ListingBasicsComponent } from './pages/listing-basics/listing-basics.component';
import { ListingWorkflowOutletComponent } from './pages/listing-workflow-outlet/listing-workflow-outlet.component';
import { ListingInstructionsComponent } from './pages/listing-instructions/listing-instructions.component';
import { ListingOfferDetailsComponent } from './pages/listing-offer-details/listing-offer-details.component';
import { ListingDetailComponent } from './pages/listing-detail/listing-detail.component';
import { ListingsOutletComponent } from './pages/listings-outlet/listings-outlet.component';
import { ManageListingsComponent } from './pages/manage-listings/manage-listings.component';
import { RequestSupportComponent } from './pages/request-support/request-support.component';
import { ConverstionCalculatorComponent } from './pages/converstion-calculator/converstion-calculator.component';

export const routeParams = {
  definitionID: ':definitionID',
  tdrListingID: ':tdrListingID',
}

const routes: Routes = [
  { path: "training", component: TrainingComponent },
  { path: "about", component: AboutComponent },
  { path: "conversion-calculator", component: ConverstionCalculatorComponent },
  { path: "help", component: HelpComponent },
  { path: "request-support", component: RequestSupportComponent },
  { path: "my-listings", component: MyListingsComponent, canActivate: [UnauthenticatedAccessGuard] },
  { path: "manage-listings", component: ManageListingsComponent, canActivate: [UnauthenticatedAccessGuard, ManagerOnlyGuard] },
  {
    path: "listings", component: ListingsOutletComponent,

    children: [
      { path: "", component: ListingListComponent },
      {
        path: "new", component: ListingWorkflowOutletComponent, canActivate: [UnauthenticatedAccessGuard], children: [
          { path: "", redirectTo: 'instructions', pathMatch: 'full' },
          { path: "instructions", component: ListingInstructionsComponent },
          { path: "basics", component: ListingBasicsComponent, canDeactivate: [UnsavedChangesGuard] },
          { path: "offer-details", component: ListingOfferDetailsComponent, canDeactivate: [UnsavedChangesGuard] }
        ]
      },
      { path: `${routeParams.tdrListingID}`, component: ListingDetailComponent},
      {
        path: `${routeParams.tdrListingID}/edit`, component: ListingWorkflowOutletComponent, canActivate: [UnauthenticatedAccessGuard, ListingWorkflowEditAccessGuard], children: [
          { path: "", redirectTo: 'instructions', pathMatch: 'full' },
          { path: "instructions", component: ListingInstructionsComponent },
          { path: "basics", component: ListingBasicsComponent, canDeactivate: [UnsavedChangesGuard] },
          { path: "offer-details", component: ListingOfferDetailsComponent, canDeactivate: [UnsavedChangesGuard] }
        ]
      },

     
     
    ]
  },
  { path: "create-user-callback", component: CreateUserCallbackComponent },
  { path: "not-found", component: NotFoundComponent },
  { path: 'subscription-insufficient', component: SubscriptionInsufficientComponent },
  { path: 'unauthenticated', component: UnauthenticatedComponent },
  { path: "signin-oidc", component: LoginCallbackComponent },
  { path: "", component: HomeIndexComponent },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    relativeLinkResolution: 'legacy',
    paramsInheritanceStrategy: 'always'
  })],
  exports: [RouterModule]
})

export class AppRoutingModule { }
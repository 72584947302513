import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomRichTextComponent } from 'src/app/shared/components/custom-rich-text/custom-rich-text.component';
import { CustomRichTextType } from 'src/app/shared/models/enums/custom-rich-text-type.enum';

@Component({
  selector: 'tdrmarketplace-listing-instructions',
  templateUrl: './listing-instructions.component.html',
  styleUrls: ['./listing-instructions.component.scss']
})
export class ListingInstructionsComponent implements OnInit {

  public customRichTextTypeID : number = CustomRichTextType.TdrListingInstructions;
  public tdrListingID: number;
  @ViewChild(CustomRichTextComponent) instructionsRichText:CustomRichTextComponent;

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    const tdrListingID = this.route.snapshot.paramMap.get("tdrListingID");
    if (tdrListingID) {
      this.tdrListingID = parseInt(tdrListingID);
    }
  }

  continueToNextStep() {
    if (this.tdrListingID) {
      this.router.navigateByUrl(`/listings/${this.tdrListingID}/edit/basics`)
    } else {
      this.router.navigateByUrl(`/listings/new/basics`)
    }
  }

  public isEditingRichText():boolean {
    
    return this.instructionsRichText != undefined &&  this.instructionsRichText.isEditing;

  }
}

import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { CustomRichTextService } from './api/custom-rich-text.service';
import { FieldDefinitionService } from './api/field-definition.service';
import { FileResourceService } from './api/file-resource.service';
import { HealthService } from './api/health.service';
import { IndicatorService } from './api/indicator.service';
import { LandCapabilityTypeService } from './api/land-capability-type.service';
import { OrganizationService } from './api/organization.service';
import { ParcelService } from './api/parcel.service';
import { RoleService } from './api/role.service';
import { SupportRequestService } from './api/support-request.service';
import { TdrListingService } from './api/tdr-listing.service';
import { UserService } from './api/user.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    CustomRichTextService,
    FieldDefinitionService,
    FileResourceService,
    HealthService,
    IndicatorService,
    LandCapabilityTypeService,
    OrganizationService,
    ParcelService,
    RoleService,
    SupportRequestService,
    TdrListingService,
    UserService,
     ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}

<div *ngIf="isLoading" class="text-center">
    <span class="fa fa-spinner loading-spinner"></span>
</div>

<div *ngIf="!isLoading" class="d-inline">
    <ng-template #popTitle>
        <div class="row">
            <div class="col-auto mr-auto">
                <div class="mt-1">{{fieldDefinitionDatum.FieldDefinition.FieldDefinitionDisplayName}}</div>
            </div>
        </div>
    </ng-template>
    <ng-template #popContent>
        <ng-container>
            <div [innerHtml]="fieldDefinitionDatum.FieldDefinitionDatumValue"></div>
        </ng-container>
    </ng-template>
    <ng-container>
        <span> 
            {{getLabelText()}}
            <i class="fas fa-question-circle small" style="cursor: help;" [ngbPopover]="popContent"  popoverClass="popover-override"
                [popoverTitle]="fieldDefinitionDatum.FieldDefinition.FieldDefinitionDisplayName" triggers="mouseenter:mouseleave" 
                container="body" [ngClass]="{'text-white': white}">
            </i>
        </span>
    </ng-container>
</div>
<nav aria-label="breadcrumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item active" aria-current="page">All Listings</li>
    </ol>
</nav>

<div class="container">
    <app-alert-display></app-alert-display>
    <div class="row mb-2">
        <div class="col-12">
            <h2 class="d-inline-block">All Listings</h2>
        </div>
        <div class="col-12">
            <custom-rich-text [customRichTextTypeID]="richTextTypeID"></custom-rich-text>
        </div>
    </div>
    <div class="row mb-2">
        <div class="col-auto ml-auto">
            <tdrmarketplace-clear-grid-filters-button [grid]="listingsGrid" class="mr-2"></tdrmarketplace-clear-grid-filters-button>
            <tdrmarketplace-csv-download-button [grid]="listingsGrid" fileName="All Published Listings"
                colIDsToExclude="[0]"></tdrmarketplace-csv-download-button>
            <a *ngIf="currentPerson" class="btn btn-tdrmarketplace ml-2" href="/listings/new">
                <i class="fa fa-plus"></i> &nbsp;
                Add New Listing
            </a>
        </div>
    </div>
    <div class="table-responsive">
        <ag-grid-angular #listingsGrid style="width: 100%; height: 400px;" class="ag-theme-balham"
            [rowData]="listings" [columnDefs]="columnDefs" [pagination]="true"
            [paginationPageSize]="100" [gridOptions]="gridOptions" ></ag-grid-angular>
    </div>
</div>
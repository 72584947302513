
<nav aria-label="breadcrumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item" aria-current="page">
        <a routerLink="/listings">Listings</a>
        </li>
        <div *ngIf="listingModel?.TdrListingID; then hasName else noName"></div>
        <ng-template #hasName>
            <li class="breadcrumb-item active" aria-current="page">
                <a routerLink="..">Listing Detail Page</a>
            </li>
        </ng-template>
        <ng-template #noName>
            <li class="breadcrumb-item active" aria-current="page">
                Create New Listing
            </li>
        </ng-template>       
    </ol>
</nav>

<app-alert-display></app-alert-display>

<div class="container-fluid mt-4">
    <div class="row">
        <div class="col-md-3">
            <tdrmarketplace-listing-wizard-sidebar [listingModel]="listingModel"></tdrmarketplace-listing-wizard-sidebar>
        </div>
        <div class="col-md-9">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>

import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { forkJoin, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ListingWorkflowService } from 'src/app/services/listing-workflow.service';
import { TdrListingService } from '../../generated/api/tdr-listing.service';
import { PersonDto } from '../../generated/model/person-dto';
import { TdrListingSimpleDto } from '../../generated/model/tdr-listing-simple-dto';
import { TdrListingUpsertDto } from '../../generated/model/tdr-listing-upsert-dto';
import { ListingStatusEnum } from '../../models/enums/listing-status.enum';

@Component({
  selector: 'tdrmarketplace-listing-wizard-sidebar',
  templateUrl: './listing-wizard-sidebar.component.html',
  styleUrls: ['./listing-wizard-sidebar.component.scss']
})
export class LstingWizardSidebarComponent implements OnInit, OnChanges, OnDestroy {
  @Input() listingModel: TdrListingUpsertDto;

  private currentUser: PersonDto;

  private _routeListener: Subscription;
  private workflowUpdateSubscription: Subscription;

  public tdrListingID: number;
  public listingStatusDisplayName: string;


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private authenticationService: AuthenticationService,
    private listingWorkflowService: ListingWorkflowService,
    private listingService: TdrListingService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.cdr.detectChanges();
  }

  ngOnInit() {
    this.authenticationService.getCurrentUser().subscribe(currentUser => {
      this.currentUser = currentUser;
      this.checkForTdrListingIDInRoute();
      this._routeListener = this.router.events
        .pipe(filter(e => e instanceof NavigationEnd))
        .subscribe((e: NavigationEnd) => {
          this.checkForTdrListingIDInRoute();
        });
      this.workflowUpdateSubscription = this.listingWorkflowService.workflowUpdate.subscribe(() => {
        this.checkForTdrListingIDInRoute();
      });


    });
  }

  ngOnDestroy() {
    this.workflowUpdateSubscription?.unsubscribe();
  }

  checkForTdrListingIDInRoute() {
    const tdrListingID = this.route.snapshot.paramMap.get("tdrListingID");
    if (tdrListingID) {
      this.tdrListingID = parseInt(tdrListingID);
      this.listingService.listingsTdrListingIDGet(this.tdrListingID).subscribe(listing => {
          
        this.listingStatusDisplayName = listing.TdrListingStatus.TdrListingStatusDisplayName
      });
    } else {
      this.listingStatusDisplayName = "New Draft";
    }
  }


  isListingBasicsComplete(): boolean {
    let model = this.listingModel;
    return model &&
      (model.TdrListingTypeID != null && model.TdrListingTypeID != undefined) &&
      (model.ParcelID != null && model.ParcelID != undefined)
  }

  isOfferDetailsComplete(): boolean {
    let model = this.listingModel;
    return model &&
      (model.LandCapabilityTypeID != null && model.LandCapabilityTypeID != undefined) &&
      (model.TransferQuantity != null && model.TransferQuantity != undefined) &&
      (model.UnitPrice != null && model.UnitPrice != undefined)
  }

}

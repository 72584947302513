<ng-container *ngIf="listingModel">
    <h2 class="d-inline-block">
        Contact & Parcel Information
    </h2>
    <custom-rich-text [customRichTextTypeID]="introRichTextTypeID"></custom-rich-text>
</ng-container>
<form (ngSubmit)="onSubmit(createListingForm)" #createListingForm="ngForm" *ngIf="listingModel">
    <div class="row">
        <div class="col-7 verticalBar pr-4">
            <h5 class="mt-4" id="contactInfoHeader">Contact Information</h5>

            <div class="row mt-3">
                <div class="col-sm-3 control-label text-sm-right">
                    <label>Name</label>
                </div>
                <div class="col-sm-9 font-weight-bold">
                    <div>
                        {{ createPersonDisplayName }}
                    </div>
                </div>
            </div>
        
            <div class="row mt-3">
                <div class="col-sm-3 control-label text-sm-right">
                    <label>Email</label>
                </div>
                <div class="col-sm-9 font-weight-bold">
                    <div>
                        {{ createPersonEmail }}
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-sm-3 control-label text-sm-right">
                    <label>Organization</label>
                </div>
                <div class="col-sm-9">
                    <input type="text" class="form-control" name="organization-name" [(ngModel)]="listingModel.OrganizationName"
                    [ngClass]='{"field-invalid": isFieldInvalid("OrganizationName")}'>
                </div>
            </div>
        
            <div class="row mt-3">
                <div class="col-sm-3 control-label text-sm-right">
                    <label>Phone Number</label>
                </div>
                <div class="col-sm-9">
                    <input type="text" class="form-control" name="phone-number" [(ngModel)]="listingModel.PhoneNumber"
                    [ngClass]='{"field-invalid": isFieldInvalid("PhoneNumber")}'>
                </div>
            </div>
        
            <div class="row mt-3">
                <div class="col-sm-3 control-label text-sm-right">
                    <label>Additional Contact Information</label>
                </div>
                <div class="col-sm-9">
                    <input type="text"class="form-control" name="contact-information" [(ngModel)]="listingModel.AdditionalContactInformation"
                    [ngClass]='{"field-invalid": isFieldInvalid("AdditionalContactInformation")}'>
                </div>
            </div>
        </div>
        <div class="col-5 pl-4">
            <div class="mt-4">
                <custom-rich-text [customRichTextTypeID]="contactHelpRichTextTypeID"></custom-rich-text>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-7 verticalBar pr-4" >
            <h5 class="mt-4" id="listingInfoHeader">Listing Information</h5>
            <div class="row mt-3">
                <div class="col-sm-3 control-label text-sm-right">
                    <label class="required">Parcel APN</label>
                </div>
                <div class="col-sm-9">
                    <parcel-typeahead [selectedParcel]="selectedParcel"></parcel-typeahead>
                </div>
            </div>
        
            <div class="row mt-4">
                <div class="col-sm-3 control-label text-sm-right">
                    <label class="required">Listing Type</label>
                </div>
                <div class="col-sm-9">
                    <ng-select [(ngModel)]="listingModel.TdrListingTypeID"
                        [items]="listingTypes" bindLabel="TdrListingTypeDisplayName" bindValue="TdrListingTypeID" 
                        placeholder="Choose One" name="TdrListingTypeID" required
                        [ngClass]='{"field-invalid": isFieldInvalid("TdrListingTypeID")}'>
                    </ng-select>
                </div>
            </div>


        </div>
        <div class="col-5 pl-4">
            <div class="mt-4" id="listingInfoHelp">
                <custom-rich-text [customRichTextTypeID]="listingHelpRichTextTypeID"></custom-rich-text>
            </div>
        </div>
    </div>
    <div class="row mt-4">
        <div class="col-sm-2">
            <i class="fas fa-bolt"></i> Required field
        </div>
        <div class="col-sm-5 text-right">
            <button type="submit" class="btn btn-outline-primary mr-2" [disabled]="isLoadingSubmit">
                <span *ngIf="isLoadingSubmit" class="fa fa-spinner loading-spinner"></span>
                Save
            </button>
            <button (click)="onSubmit($event, true)" class="btn btn-tdrmarketplace" [disabled]="isLoadingSubmit">
                <span *ngIf="isLoadingSubmit" class="fa fa-spinner loading-spinner"></span>
                Save and Continue
            </button>
            <a *ngIf="!listingModel.TdrListingID" class="btn btn-secondary ml-1" routerLink="/my-listings">Cancel</a>
            <a *ngIf="listingModel.TdrListingID" class="btn btn-secondary ml-1" routerLink="../../">Cancel</a>

        </div>
    </div>
</form>

<div class="container">
    <app-alert-display></app-alert-display>
    <form (ngSubmit)="onSubmit(supportRequestForm)" #supportRequestForm="ngForm" *ngIf="supportRequestLogModel">
        <h5 class="mt-4">Request Support</h5>
    
        <div class="row mt-3">
            <div class="col-sm-3 control-label text-sm-right">
                <label class="required">Name</label>
            </div>
            <div class="col-sm-7 font-weight-bold">
                <div *ngIf="currentUser">
                    {{ currentUser.FullName }}
                </div>
                <input *ngIf="!currentUser" type="text" class="form-control" name="request-name" [(ngModel)]="supportRequestLogModel.RequestPersonName"
                    [ngClass]='{"field-invalid": isFieldInvalid("RequestPersonName")}'>
            </div>
        </div>
    
        <div  class="row mt-3">
            <div class="col-sm-3 control-label text-sm-right">
                <label class="required">Email</label>
            </div>
            <div class="col-sm-7 font-weight-bold">
                <div *ngIf="currentUser" >
                    {{ currentUser.Email }}
                </div>
                <input *ngIf="!currentUser" type="text" class="form-control" name="email" [(ngModel)]="supportRequestLogModel.RequestPersonEmail"
                    [ngClass]='{"field-invalid": isFieldInvalid("RequestPersonEmail")}'>
            </div>
        </div>
    
    
        <div class="row mt-3">
            <div class="col-sm-3 control-label text-sm-right">
                <label>Organization</label>
            </div>
            <div class="col-sm-7">
                <input type="text"class="form-control" name="organization" [(ngModel)]="supportRequestLogModel.RequestPersonOrganization"
                [ngClass]='{"field-invalid": isFieldInvalid("RequestPersonOrganization")}'>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-sm-3 control-label text-sm-right">
                <label>Phone Number</label>
            </div>
            <div class="col-sm-7">
                <input type="text" class="form-control" name="phone-number" [(ngModel)]="supportRequestLogModel.RequestPersonPhone"
                [ngClass]='{"field-invalid": isFieldInvalid("RequestPersonPhone")}'>
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-sm-3 control-label text-sm-right">
                <label class="required">Subject</label>
            </div>
            <div class="col-sm-7">
                <ng-select [(ngModel)]="supportRequestLogModel.SupportRequestTypeID"
                    [items]="supportRequesTypes" bindLabel="SupportRequestTypeDisplayName" bindValue="SupportRequestTypeID" 
                    placeholder="Choose One" name="SupportRequestTypeID" required
                    [ngClass]='{"field-invalid": isFieldInvalid("SupportRequestTypeID")}'>
                </ng-select>
            </div>
        </div>
    
        <div class="row mt-3">
            <div class="col-sm-3 control-label text-sm-right">
                <label class="required">Description</label>
            </div>
            <div class="col-sm-7">
                <textarea class="form-control" name="description" style="height: 100px" placeholder=""
                    [(ngModel)]="supportRequestLogModel.RequestDescription">
                </textarea>
                <em *ngIf="!supportRequestLogModel.RequestDescription" class="text-muted">Characters Remaining: 2000</em>
                <em *ngIf="supportRequestLogModel.RequestDescription && supportRequestLogModel.RequestDescription.length <= 500" class="text-muted">Characters Remaining: {{2000 - supportRequestLogModel.RequestDescription.length}}</em>
                <em *ngIf="supportRequestLogModel.RequestDescription && supportRequestLogModel.RequestDescription.length > 500" class="text-danger">Characters Remaining: {{2000 - supportRequestLogModel.RequestDescription.length}}</em>
            </div>
        </div>
    
        <div class="row mt-4">
            <div class="offset-sm-1 col-sm-11">
                <i class="fas fa-bolt"></i> Required field
            </div>
            <div *ngIf="!currentUser" class="offset-sm-1 col-sm-11 mt-3 mb-3">
                <small>This site is protected by reCAPTCHA and the Google
                    <a href="https://policies.google.com/privacy">Privacy Policy</a> and
                    <a href="https://policies.google.com/terms">Terms of Service</a> apply.
                </small>
            </div>
            <div class="offset-sm-3 col-sm-7 text-right">
                <button type="submit" class="btn btn-outline-primary mr-2" [disabled]="isLoadingSubmit">
                    <span *ngIf="isLoadingSubmit" class="fa fa-spinner loading-spinner"></span>
                    Submit Request
                </button>
                <a class="btn btn-secondary ml-1" routerLink="/">Cancel</a>
            </div>
        </div>

    </form>
</div>


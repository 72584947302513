<ng-container *ngIf="isInformational">
    <tdrmarketplace-icon 
        icon="info" 
        class="info"
        ngbTooltip="Informational Page" 
        container="body">
    </tdrmarketplace-icon>
</ng-container>

<ng-container *ngIf="!isInformational">
    <tdrmarketplace-icon 
        [icon]="isComplete ? 'check' : 'edit'" 
        [class.complete]="isComplete" 
        [class.incomplete]="!isComplete" 
        [ngbTooltip]="isComplete ? tooltipCompleteText : tooltipIncompleteText" 
        container="body">
    </tdrmarketplace-icon>
</ng-container>
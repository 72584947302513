<div class="sidebar-header p-2">
    <div class="row m-2">
        <span>Listing Status: &nbsp;</span> 
        <div *ngIf="listingModel?.TdrListingID; then hasID else noID"></div>
        <ng-template #hasID>
            <span><strong>{{ listingStatusDisplayName }}</strong></span>
        </ng-template>
        <ng-template #noID>
            <span><strong>New Draft</strong></span>
        </ng-template>  
    </div>
    <div class="row m-2">
        <div *ngIf="listingModel?.TdrListingID">
            <a routerLink=".."><span class="fa fa-chevron-left"></span> Back to Listing Detail page</a>
        </div>
    </div>
</div>

<div class="sidebar-card">
    <div class="card-body">
        <a [routerLink]="['instructions']"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            class="icon-padding">
            <tdrmarketplace-progress-icon [isInformational]="true"></tdrmarketplace-progress-icon>
            Instructions
        </a>
    </div>
    <div class="card-body">
        <a [routerLink]="['basics']"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            class="icon-padding">
            <tdrmarketplace-progress-icon [isComplete]="isListingBasicsComplete()"></tdrmarketplace-progress-icon>
            Contact & Parcel Information
        </a>
    </div>

    <div class="card-body" [ngClass]="{'not-allowed': listingModel != undefined && !listingModel.TdrListingID }">
        <a [routerLink]="['offer-details']" 
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            class="icon-padding"
            [ngClass]="{'disabled': listingModel != undefined &&  !listingModel.TdrListingID}">
            <tdrmarketplace-progress-icon [isComplete]="isOfferDetailsComplete()"></tdrmarketplace-progress-icon>
            Offer Details
        </a>
    </div>


</div>


import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { PersonDto } from 'src/app/shared/generated/model/person-dto';
import { AlertService } from 'src/app/shared/services/alert.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { TdrListingUpsertDto } from 'src/app/shared/generated/model/tdr-listing-upsert-dto';
import { TdrListingService } from 'src/app/shared/generated/api/tdr-listing.service';
import { ListingWorkflowService } from 'src/app/services/listing-workflow.service';
import { TdrListingSimpleDto } from 'src/app/shared/generated/model/tdr-listing-simple-dto';

@Component({
  selector: 'tdrmarketplace-listing-workflow-outlet',
  templateUrl: './listing-workflow-outlet.component.html',
  styleUrls: ['./listing-workflow-outlet.component.scss']
})
export class ListingWorkflowOutletComponent implements OnInit {

  
  public currentUser: PersonDto;

  private workflowUpdateSubscription: Subscription;
  
  public tdrListingID: number;
  public listingModel: TdrListingUpsertDto;
  public isLoadingSubmit = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private authenticationService: AuthenticationService,
    private listingService: TdrListingService,
    private listingWorkflowService: ListingWorkflowService
  ) { }

  ngOnInit(): void {
    this.authenticationService.getCurrentUser().subscribe(currentUser => {
      this.currentUser = currentUser;
      
      this.listingModel = new TdrListingUpsertDto();
      this.parseURLForListingIDAndGetEntitiesIfPresent();

      this.workflowUpdateSubscription = this.listingWorkflowService.workflowUpdate.subscribe(() => {
        this.parseURLForListingIDAndGetEntitiesIfPresent();
      })

      this.cdr.detectChanges();
    });
  }

  ngOnDestroy() {
    this.cdr.detach();
  }

  private parseURLForListingIDAndGetEntitiesIfPresent() {
    const tdrListingID = this.route.snapshot.paramMap.get("tdrListingID");
      if (tdrListingID) {
        this.tdrListingID = parseInt(tdrListingID);
        this.listingService.listingsTdrListingIDGet(this.tdrListingID).subscribe(listing => {
          this.mapListingSimpleDtoToListingModel(listing);
        });
      }
  }

  private mapListingSimpleDtoToListingModel(listing: TdrListingSimpleDto) {
    this.listingModel.TdrListingID = listing.TdrListingID;
    this.listingModel.TdrListingTypeID = listing.TdrListingTypeID;
    this.listingModel.ParcelID = listing.ParcelID;
    this.listingModel.ParcelNumber = listing.Parcel?.ParcelNumber;
    this.listingModel.LandCapabilityTypeID = listing.LandCapabilityTypeID;
    this.listingModel.UnitPrice = listing.UnitPrice;
    this.listingModel.TransferQuantity = listing.TransferQuantity;
    this.listingModel.CreatePersonID = listing.CreatePersonID;
    this.listingModel.OrganizationName = listing.OrganizationName;
    this.listingModel.PhoneNumber = listing.PhoneNumber;
    this.listingModel.AdditionalContactInformation = listing.AdditionalContactInformation;
    this.listingModel.Notes = listing.Notes;
  }

}



<div class="container mt-4">
    <app-alert-display></app-alert-display>
    <h1 class="text-primary mb-4">{{platformLongName()}}</h1>
    <div class="row mt-sm-4">
        <div class="col-md-4 order-md-12 order-1 col-sm-12">
            <div class="card">
                <div class="card-header">
                    <ng-container *ngIf="!currentUser">Access</ng-container>
                    <ng-container *ngIf="currentUser">Quick Actions</ng-container>
                </div>
                <div class="card-body" *ngIf="!currentUser">
                    <div class="row">
                        <div class="col">
                            Log in to view your Account. Create an Account if you don't have one yet.
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col mx-1">
                            <button (click)="login()" class="btn btn-tdrmarketplace mr-1">Log In</button>
                            <button (click)="createAccount()" class="btn btn-tdrmarketplace ml-1">Create
                                Account</button>
                        </div>
                    </div>
                    <hr />
                    <div class="row">
                        <div class="col">
                            <small style="font-size:11px;">
                                Need help logging in? <br />
                                <a href="{{forgotPasswordUrl()}}">Forgot Password</a> | <a
                                    href="{{forgotUsernameUrl()}}">Forgot
                                    Username</a> | <a href="{{keystoneSupportUrl()}}">Request Support</a>
                            </small>
                        </div>
                    </div>
                </div>
                <div class="card-body" *ngIf="currentUser">
                    <a class="btn btn-tdrmarketplace mb-1 w-100" routerLink="/my-listings">My Listings</a>
                    <a class="btn btn-tdrmarketplace mb-1 w-100" routerLink="/listings">All Listings</a>
                    <a class="btn btn-tdrmarketplace mb-1 w-100" routerLink="/listings/new"><i class="fa fa-plus"></i> &nbsp;Add New Listing</a>
                    <a class="btn btn-tdrmarketplace mb-1 w-100" href="{{parcelTrackerBaseUrl()}}">Parcel Tracker</a>
                </div>
            </div>
        </div>
        
        <div class="col-md-8 order-md-1 order-12 col-sm-12">
            <div class="mt-2">
                <custom-rich-text [customRichTextTypeID]="richTextTypeID"></custom-rich-text>
            </div>
        </div>
    </div>
</div>
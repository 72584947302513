<header-nav></header-nav>

<div class="mh-auto min-vh-100 page-content">
    
        <!--This is to avoid reload during acquireTokenSilent() because of hidden iframe -->
        <router-outlet *ngIf="!isIframe"></router-outlet>
    
</div>
<!--
<main class="site">
    <div class="site-wrapper">
        
        <router-outlet *ngIf="!isIframe"></router-outlet>
    </div>
</main>
-->
<footer class="footer mt-2 pt-4 pb-3">
    <div class="container">
        <p class="col text-center">
            Implementation & Hosting by <a href="https://esassoc.com/" target="_blank">Environmental Science Associates</a>.
        </p>
        <p class="text-center">
            Portions of the Lake Tahoe Info platform may be freely modified and redistributed. The open source version of the
            EIP Project Tracker is available as <a href="https://projectfirma.com">ProjectFirma</a> on <a href="https://github.com/sitkatech/projectfirma">GitHub</a>. The source code for the Stormwater Tools and Tahoe Development Rights Marketplace is available upon request.
            Other components of LT Info are available via commercial licensing through the Tahoe Regional Planning Agency (<a href="mailto:trpa@trpa.gov">trpa@trpa.gov</a>).
        </p>
        
    </div>
</footer>

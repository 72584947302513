/**
 * LtInfo.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { TdrListingSimpleDto } from '../model/tdr-listing-simple-dto';
import { TdrListingTypeDto } from '../model/tdr-listing-type-dto';
import { TdrListingUpsertDto } from '../model/tdr-listing-upsert-dto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { catchError } from 'rxjs/operators';
import { ApiService } from '../../services';


@Injectable({
  providedIn: 'root'
})
export class TdrListingService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration
    , private apiService: ApiService) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public allListingsGet(observe?: 'body', reportProgress?: boolean): Observable<Array<TdrListingSimpleDto>>;
    public allListingsGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TdrListingSimpleDto>>>;
    public allListingsGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TdrListingSimpleDto>>>;
    public allListingsGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<TdrListingSimpleDto>>(`${this.basePath}/all-listings`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingTypesGet(observe?: 'body', reportProgress?: boolean): Observable<Array<TdrListingTypeDto>>;
    public listingTypesGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TdrListingTypeDto>>>;
    public listingTypesGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TdrListingTypeDto>>>;
    public listingTypesGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<TdrListingTypeDto>>(`${this.basePath}/listing-types`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param tdrListingUpsertDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingsNewPost(tdrListingUpsertDto?: TdrListingUpsertDto, observe?: 'body', reportProgress?: boolean): Observable<TdrListingSimpleDto>;
    public listingsNewPost(tdrListingUpsertDto?: TdrListingUpsertDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TdrListingSimpleDto>>;
    public listingsNewPost(tdrListingUpsertDto?: TdrListingUpsertDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TdrListingSimpleDto>>;
    public listingsNewPost(tdrListingUpsertDto?: TdrListingUpsertDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<TdrListingSimpleDto>(`${this.basePath}/listings/new`,
            tdrListingUpsertDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param tdrListingID 
     * @param tdrListingUpsertDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingsTdrListingIDBasicsUpdatePut(tdrListingID: number, tdrListingUpsertDto?: TdrListingUpsertDto, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public listingsTdrListingIDBasicsUpdatePut(tdrListingID: number, tdrListingUpsertDto?: TdrListingUpsertDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public listingsTdrListingIDBasicsUpdatePut(tdrListingID: number, tdrListingUpsertDto?: TdrListingUpsertDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public listingsTdrListingIDBasicsUpdatePut(tdrListingID: number, tdrListingUpsertDto?: TdrListingUpsertDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tdrListingID === null || tdrListingID === undefined) {
            throw new Error('Required parameter tdrListingID was null or undefined when calling listingsTdrListingIDBasicsUpdatePut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/listings/${encodeURIComponent(String(tdrListingID))}/basics/update`,
            tdrListingUpsertDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param tdrListingID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingsTdrListingIDDelete(tdrListingID: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public listingsTdrListingIDDelete(tdrListingID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public listingsTdrListingIDDelete(tdrListingID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public listingsTdrListingIDDelete(tdrListingID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tdrListingID === null || tdrListingID === undefined) {
            throw new Error('Required parameter tdrListingID was null or undefined when calling listingsTdrListingIDDelete.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/listings/${encodeURIComponent(String(tdrListingID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param tdrListingID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingsTdrListingIDDelistPut(tdrListingID: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public listingsTdrListingIDDelistPut(tdrListingID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public listingsTdrListingIDDelistPut(tdrListingID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public listingsTdrListingIDDelistPut(tdrListingID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tdrListingID === null || tdrListingID === undefined) {
            throw new Error('Required parameter tdrListingID was null or undefined when calling listingsTdrListingIDDelistPut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.put<any>(`${this.basePath}/listings/${encodeURIComponent(String(tdrListingID))}/delist`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param tdrListingID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingsTdrListingIDGet(tdrListingID: number, observe?: 'body', reportProgress?: boolean): Observable<TdrListingSimpleDto>;
    public listingsTdrListingIDGet(tdrListingID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TdrListingSimpleDto>>;
    public listingsTdrListingIDGet(tdrListingID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TdrListingSimpleDto>>;
    public listingsTdrListingIDGet(tdrListingID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tdrListingID === null || tdrListingID === undefined) {
            throw new Error('Required parameter tdrListingID was null or undefined when calling listingsTdrListingIDGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<TdrListingSimpleDto>(`${this.basePath}/listings/${encodeURIComponent(String(tdrListingID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param tdrListingID 
     * @param tdrListingUpsertDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingsTdrListingIDOfferDetailsUpdatePut(tdrListingID: number, tdrListingUpsertDto?: TdrListingUpsertDto, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public listingsTdrListingIDOfferDetailsUpdatePut(tdrListingID: number, tdrListingUpsertDto?: TdrListingUpsertDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public listingsTdrListingIDOfferDetailsUpdatePut(tdrListingID: number, tdrListingUpsertDto?: TdrListingUpsertDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public listingsTdrListingIDOfferDetailsUpdatePut(tdrListingID: number, tdrListingUpsertDto?: TdrListingUpsertDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tdrListingID === null || tdrListingID === undefined) {
            throw new Error('Required parameter tdrListingID was null or undefined when calling listingsTdrListingIDOfferDetailsUpdatePut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/listings/${encodeURIComponent(String(tdrListingID))}/offer-details/update`,
            tdrListingUpsertDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param tdrListingID 
     * @param tdrListingUpsertDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingsTdrListingIDPublishPut(tdrListingID: number, tdrListingUpsertDto?: TdrListingUpsertDto, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public listingsTdrListingIDPublishPut(tdrListingID: number, tdrListingUpsertDto?: TdrListingUpsertDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public listingsTdrListingIDPublishPut(tdrListingID: number, tdrListingUpsertDto?: TdrListingUpsertDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public listingsTdrListingIDPublishPut(tdrListingID: number, tdrListingUpsertDto?: TdrListingUpsertDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tdrListingID === null || tdrListingID === undefined) {
            throw new Error('Required parameter tdrListingID was null or undefined when calling listingsTdrListingIDPublishPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/listings/${encodeURIComponent(String(tdrListingID))}/publish`,
            tdrListingUpsertDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param tdrListingID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listingsTdrListingIDRelistPut(tdrListingID: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public listingsTdrListingIDRelistPut(tdrListingID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public listingsTdrListingIDRelistPut(tdrListingID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public listingsTdrListingIDRelistPut(tdrListingID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tdrListingID === null || tdrListingID === undefined) {
            throw new Error('Required parameter tdrListingID was null or undefined when calling listingsTdrListingIDRelistPut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.put<any>(`${this.basePath}/listings/${encodeURIComponent(String(tdrListingID))}/relist`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param personID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public myListingsPersonIDGet(personID: number, observe?: 'body', reportProgress?: boolean): Observable<Array<TdrListingSimpleDto>>;
    public myListingsPersonIDGet(personID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TdrListingSimpleDto>>>;
    public myListingsPersonIDGet(personID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TdrListingSimpleDto>>>;
    public myListingsPersonIDGet(personID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (personID === null || personID === undefined) {
            throw new Error('Required parameter personID was null or undefined when calling myListingsPersonIDGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<TdrListingSimpleDto>>(`${this.basePath}/my-listings/${encodeURIComponent(String(personID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public publishedListingsGet(observe?: 'body', reportProgress?: boolean): Observable<Array<TdrListingSimpleDto>>;
    public publishedListingsGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TdrListingSimpleDto>>>;
    public publishedListingsGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TdrListingSimpleDto>>>;
    public publishedListingsGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<TdrListingSimpleDto>>(`${this.basePath}/published-listings`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

}

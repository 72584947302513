declare var window: any;

export class DynamicEnvironment {
    private _production: boolean;

    constructor(_production: boolean){
        this._production = _production
    }

    public get production() {
        if (window.config) {
            return window.config.production;
        } else return this._production;
    }

    public get staging() {
        return window.config.staging;
    }

    public get dev() {
        return window.config.dev;
    }

    public get mainAppApiUrl() {
        return window.config.mainAppApiUrl;
    }

    public get createAccountUrl() {
        return window.config.createAccountUrl;
    }

    public get createAccountRedirectUrl() {
        return window.config.createAccountRedirectUrl;
    }

    public get keystoneSupportBaseUrl() {
        return window.config.keystoneSupportBaseUrl;
    }

    public get keystoneAuthConfiguration() {
        return window.config.keystoneAuthConfiguration;
    }

    public get platformLongName(){
        return window.config.platformLongName;
    }

    public get platformShortName(){
        return window.config.platformShortName;
    }

    public get faviconFilename(){
        return window.config.faviconFilename;
    }

    public get appInsightsInstrumentationKey() {
        return window.config.appInsightsInstrumentationKey;
    }

    public get geoserverMapServiceUrl() {
        return window.config.geoserverMapServiceUrl;
    }

    public get lakeTahoeInfoBaseUrl(){
        return window.config.lakeTahoeInfoBaseUrl;
    }

    public get parcelTrackerBaseUrl(){
        return window.config.parcelTrackerBaseUrl;
    }

    public get climateDashboardBaseUrl(){
        return window.config.climateDashboardBaseUrl;
    }

    public get eipProjectTrackerBaseUrl(){
        return window.config.eipProjectTrackerBaseUrl;
    }

    public get lakeClarityTrackerBaseUrl(){
        return window.config.lakeClarityTrackerBaseUrl;
    }

    public get monitoringDashboardBaseUrl(){
        return window.config.monitoringDashboardBaseUrl;
    }

    public get thresholdDashboardBaseUrl(){
        return window.config.thresholdDashboardBaseUrl;
    }

    public get transportationTrackerBaseUrl(){
        return window.config.transportationTrackerBaseUrl;
    }

    public get recaptchaV3SiteKey() {
        //ng-recaptcha has certain  limitations, in  this case they only accept a  hard coded string as opposed to allowing for a promise
        //Check periodically to see  if this has changed, and when it has call window.config as opposed to using this
        return "6Le6u1MmAAAAACYTIsnBYycKOyTB0KwRgPwR8iGH";
    }
}

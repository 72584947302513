<div class="container" *ngIf="listing">
    <app-alert-display></app-alert-display>
    <div class="row mt-3 align-items-center" *ngIf="!isReadOnly">
        <!-- <div class="col-9">
            <h2 class="d-inline-block">{{listing?.TdrListingStatus?.TdrListingStatusDisplayName}}</h2>
        </div> -->

        <div class="col-auto ml-auto" >
            <a routerLink="./edit" class="btn btn-tdrmarketplace">Edit</a>
            <button *ngIf="canDelist" type="button" class="btn btn-tdrmarketplace ml-1" (click)="onDelistClicked()">
                Delist Posting
            </button>
            <button *ngIf="canRelist" type="button" class="btn btn-tdrmarketplace ml-1" (click)="onRelistClicked()">
                Relist Posting
            </button>
        </div>

        
    </div>
    <div class="row mb-3">
        <div class="col-12 col-md-6 mt-2">
            <div class="card">
                <div class="card-header">
                    Contact & Parcel Information
                </div>
                <div class="card-body">
                    <dl class="row">
                        <dt class="text-sm-right col-sm-5 col-xs-12">Status</dt>
                        <dd class="col-sm-7 col-xs-12">
                            {{listing?.TdrListingStatus?.TdrListingStatusDisplayName}}
                        </dd>
                        <dt class="text-sm-right col-sm-5 col-xs-12">Listing Type</dt>
                        <dd class="col-sm-7 col-xs-12">
                            {{listing?.TdrListingType.TdrListingTypeDisplayName}}
                        </dd>
                        <dt class="text-sm-right col-sm-5 col-xs-12">Posting Date</dt>
                        <dd class="col-sm-7 col-xs-12">
                            {{listing?.PostingDate | date: 'MM/dd/yyyy'}}
                        </dd>
                        <dt class="text-sm-right col-sm-5 col-xs-12">Name</dt>
                        <dd class="col-sm-7 col-xs-12">
                            {{listing?.CreatePerson.FirstName}} {{listing?.CreatePerson.LastName}}
                        </dd>

                        <dt class="text-sm-right col-sm-5 col-xs-12">Email</dt>
                        <dd class="col-sm-7 col-xs-12">
                            {{listing?.CreatePerson.Email}}
                        </dd>

                        <dt class="text-sm-right col-sm-5 col-xs-12">Organization</dt>
                        <dd class="col-sm-7 col-xs-12">
                            {{listing?.OrganizationName}}
                            <em *ngIf="!listing?.OrganizationName" class="text-muted">None Provided</em>
                        </dd>

                        <dt class="text-sm-right col-sm-5 col-xs-12">Phone Number</dt>
                        <dd class="col-sm-7 col-xs-12">
                            {{listing?.PhoneNumber}}
                            <em *ngIf="!listing?.PhoneNumber" class="text-muted">None Provided</em>
                        </dd>

                        <dt class="text-sm-right col-sm-5 col-xs-12">Additional Contact Information</dt>
                        <dd class="col-sm-7 col-xs-12">
                            {{listing?.AdditionalContactInformation}}
                            <em *ngIf="!listing?.AdditionalContactInformation" class="text-muted">None Provided</em>
                        </dd>

                        <dt class="text-sm-right col-sm-5 col-xs-12">Parcel APN</dt>
                        <dd class="col-sm-7 col-xs-12">
                            <a href="{{listing?.Parcel?.DetailUrl}}">{{listing?.Parcel.ParcelNumber}}</a>
                        </dd>
                        <dt class="text-sm-right col-sm-5 col-xs-12">Address</dt>
                        <dd class="col-sm-7 col-xs-12">
                            <div>
                                {{ listing?.Parcel?.ParcelStreetAddress }}
                                <br/>
                                {{ listing?.Parcel?.ParcelCity }}, {{ listing?.Parcel?.ParcelState }} {{ listing?.Parcel?.ParcelZipCode}}
                            </div>
                        </dd>
                        <dt class="text-sm-right col-sm-5 col-xs-12">Jurisdiction</dt>
                        <dd class="col-sm-7 col-xs-12">
                            <div>
                                {{ listing?.Parcel?.Jurisdiction.Organization.OrganizationName }}
                            </div>
                        </dd>
                        <dt class="text-sm-right col-sm-5 col-xs-12">HRA</dt>
                        <dd class="col-sm-7 col-xs-12">
                            <div>
                                {{ listing?.Parcel?.HRA }}
                            </div>
                        </dd>
                    </dl>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6 mt-2">
            <div class="card">
                <div class="card-header">
                    Offer Details
                </div>
                <div class="card-body">
                    <dl class="row">
                        <dt class="text-sm-right col-sm-5 col-xs-12">Development Right</dt>
                        <dd class="col-sm-7 col-xs-12">
                            {{listing?.LandCapabilityType?.Commodity.CommodityDisplayName}}
                        </dd>

                        <dt class="text-sm-right col-sm-5 col-xs-12">Land Capability</dt>
                        <dd class="col-sm-7 col-xs-12">
                            {{listing?.LandCapabilityType?.BaileyRatingOrIpesDisplayName}}
                        </dd>

                        <dt class="text-sm-right col-sm-5 col-xs-12">Quantity</dt>
                        <dd class="col-sm-7 col-xs-12">
                            {{listing?.TransferQuantity}} {{listing?.LandCapabilityType?.Commodity?.CommodityUnitType.CommodityUnitTypeAbbreviation}}
                        </dd>

                        <dt class="text-sm-right col-sm-5 col-xs-12">Unit Price</dt>
                        <dd class="col-sm-7 col-xs-12">
                            {{getCurrencyDisplay(listing?.UnitPrice)}}
                        </dd>

                        <dt class="text-sm-right col-sm-5 col-xs-12">Notes on Listing</dt>
                        <dd class="col-sm-7 col-xs-12">
                            {{listing?.Notes}}
                            <em *ngIf="!listing?.Notes" class="text-muted">None Provided</em>
                        </dd>
                    </dl>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #delistListingModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title text-white" id="delistListingModalTitle">Delist?</h5>
        <button type="button" class="close" aria-label="Close" (click)="modal.close('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>
            You are about to delist {{listingToDelistOrRelistDisplay}}.
        </p>
        <p>Are you sure you wish to proceed?</p>
        <div class="modal-footer">
            <button type="button" class="btn btn-danger btn-sm" (click)="delistListing()">
                <span *ngIf="isLoadingDelist" class="fa fa-spinner loading-spinner"></span>
                Confirm
            </button>
            <button type="button" class="btn btn-secondary btn-sm" (click)="modal.close('Cancel click')">Cancel</button>
        </div>
    </div>
</ng-template>

<ng-template #relistListingModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title text-white" id="relistListingModalTitle">Relist?</h5>
        <button type="button" class="close" aria-label="Close" (click)="modal.close('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>
            You are about to relist {{listingToDelistOrRelistDisplay}}.
        </p>
        <p>Are you sure you wish to proceed?</p>
        <div class="modal-footer">
            <button type="button" class="btn btn-tdrmarketplace btn-sm" (click)="relistListing()">
                <span *ngIf="isLoadingRelist" class="fa fa-spinner loading-spinner"></span>
                Confirm
            </button>
            <button type="button" class="btn btn-secondary btn-sm" (click)="modal.close('Cancel click')">Cancel</button>
        </div>
    </div>
</ng-template>
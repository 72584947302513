<nav aria-label="breadcrumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item active" aria-current="page">Conversion Calculator</li>
    </ol>
</nav>

<div class="container">
    <app-alert-display></app-alert-display>
    <div class="row mb-2">
        <div class="col">
            <h2 class="d-inline-block">Conversion Calculator</h2>

        </div>
    </div>
    <div class="row mt-4">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    Conversion Ratio Calculator
                </div>
                <div class="card-body">
                    <table class="table table-condensed ">
                        <tr>
                            <th></th>
                            <th>Development Right</th>
                            <th>Quantity</th>
                        </tr>
                        <tr>
                            <td><label>Existing:</label></td>
                            <td>
                                <ng-select [(ngModel)]="existingCommodity" [items]="commodities"
                                    bindLabel="CommodityDisplayName" placeholder="Choose One" name="existingCommodity"
                                    (change)="updateExistingCommodity($event)">
                                </ng-select>
                            </td>
                            <td>
                                <div class="input-group">
                                    <input type="number" min="0" class="form-control" name="existingQuantity"
                                        [(ngModel)]="existingQuantity" (change)="onQuantityChange($event)" (keyup)="onQuantityChange($event)">
                                    <div *ngIf="existingQuantityUnit" class="input-group-append">
                                        <span class="input-group-text">{{existingQuantityUnit}}</span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td><label>Converted To:</label></td>
                            <td>
                                <ng-select [(ngModel)]="convertedToCommodity" [items]="commodities"
                                    bindLabel="CommodityDisplayName" placeholder="Choose One"
                                    name="convertedToCommodity" (change)="updateConvertedToCommodity($event)">
                                </ng-select>
                            </td>
                            <td class="col-3">
                                <div class="input-group">
                                    <span>{{convertedToQuantity}}</span>&nbsp;
                                    <span id="ConvertedCommodityUnits">{{convertedToQuantityUnit}}</span>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td><label>Remainder:</label></td>
                            <td>
                                <ng-select [(ngModel)]="remainderCommodity" [items]="commodities"
                                    bindLabel="CommodityDisplayName" placeholder="Choose One" name="remainderCommodity"
                                    (change)="updateRemainderCommodity($event)">
                                </ng-select>
                            </td>
                            <td class="col-3">
                                <div class="input-group">
                                    <span>{{remainderQuantity}}</span>&nbsp;
                                    <span id="ConvertedCommodityUnits">{{remainderQuantityUnit}}</span>
                                </div>
                            </td>
                        </tr>
                    </table>
                    <hr />

                    <p>Conversion Ratio Definitions:</p>
                    <table class="table table-condensed table-bordered">
                        <tr>
                            <th rowspan="2">
                                Existing Development Right
                            </th>
                            <th colspan="4">Equivalent Development Rights</th>
                        </tr>
                        <tr>
                            <th>CFA</th>
                            <th>TAU</th>
                            <th>SF</th>
                            <th>MF</th>
                        </tr>
                        <tr>
                            <td>300 sq ft Commercial Floor Area (CFA)</td>
                            <td>300 sq ft</td>
                            <td>1</td>
                            <td>1</td>
                            <td>3/2</td>
                        </tr>
                        <tr>
                            <td>1 Tourist Accommodation Unit (TAU)</td>
                            <td>300 sq ft</td>
                            <td>1</td>
                            <td>1</td>
                            <td>3/2</td>
                        </tr>
                        <tr>
                            <td>1 Single Family Detached Dwelling Unit (SF)</td>
                            <td>300 sq ft</td>
                            <td>1</td>
                            <td>1</td>
                            <td>3/2</td>
                        </tr>
                        <tr>
                            <td>1 Multi-Family Attached Dwelling Unit (MF)</td>
                            <td>200 sq ft</td>
                            <td>2/3</td>
                            <td>2/3</td>
                            <td>1</td>
                        </tr>
                    </table>

                </div>
            </div>
        </div>
    </div>
</div>
export enum LTInfoAreaEnum {
  EIP = 1,
  LTInfo = 3,
  ParcelTracker = 4,
  Stormwater = 6,
  Monitoring = 7,
  Transportation = 8,
  LakeClarityTracker = 9,
  Threshold = 10,
  Marketplace = 11,
  ClimateResilience = 12
}

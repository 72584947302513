import { CurrencyPipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { TdrListingService } from 'src/app/shared/generated/api/tdr-listing.service';
import { PersonDto, TdrListingSimpleDto } from 'src/app/shared/generated/model/models';
import { Alert } from 'src/app/shared/models/alert';
import { AlertContext } from 'src/app/shared/models/enums/alert-context.enum';
import { ListingStatusEnum } from 'src/app/shared/models/enums/listing-status.enum';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ConfirmService } from 'src/app/shared/services/confirm.service';

@Component({
  selector: 'tdrmarketplace-listing-detail',
  templateUrl: './listing-detail.component.html',
  styleUrls: ['./listing-detail.component.scss']
})
export class ListingDetailComponent implements OnInit {
  @ViewChild('delistListingModal') delistListingModal
  @ViewChild('relistListingModal') relistListingModal

  private tdrListingID: number;
  private currentUser: PersonDto;

  public listing: TdrListingSimpleDto;
  public isReadOnly: boolean;
  public canDelist: boolean;
  public canRelist: boolean;
  private modalReference: NgbModalRef;
  private listingToDelistOrRelistDisplay: string;
  private isLoadingDelist = false;
  private isLoadingRelist = false;

  constructor(
    private authenticationService: AuthenticationService,
    private listingService: TdrListingService,
    private route: ActivatedRoute,
    private router: Router,
    private confirmService: ConfirmService,
    private alertService: AlertService,
    private cdr: ChangeDetectorRef,
    private modalService: NgbModal
  ) { 
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit(): void {


    const tdrListingID = this.route.snapshot.paramMap.get("tdrListingID");
    if (tdrListingID) {
      this.tdrListingID = parseInt(tdrListingID);
      this.refreshListingData();
    }
  }

  private refreshListingData(){
    this.listingService.listingsTdrListingIDGet(this.tdrListingID).subscribe(listing => {
      this.listing = listing;
      this.isReadOnly = true;
      this.canDelist = false;
      this.canRelist = false;

      this.authenticationService.getCurrentUser().subscribe(currentUser => {
        this.currentUser = currentUser;
        this.isReadOnly = this.authenticationService.isUserUnassigned(currentUser) || !(this.authenticationService.isCurrentUserLTInfoAdministrator() || this.authenticationService.isCurrentUserParcelTrackerAdministrator());
        this.isReadOnly = this.isReadOnly && this.currentUser.PersonID != listing.CreatePersonID;
        this.canDelist = !this.isReadOnly && listing.TdrListingStatusID == ListingStatusEnum.Published;
        this.canRelist = !this.isReadOnly && listing.TdrListingStatusID == ListingStatusEnum.Delisted;

      });
    });
  }


  getWorkflowLink() {
    return `/listings/edit/${this.tdrListingID}`;
  }

  private getCurrencyDisplay(currency: number): string {
    let currencyPipe = new CurrencyPipe('en-US');

    return currencyPipe.transform(currency, null, "symbol")
  }


  public onDelistClicked(event: any): void {
      this.listingToDelistOrRelistDisplay =`an ${this.listing.TdrListingType.TdrListingTypeDisplayName} for APN ${this.listing.Parcel.ParcelNumber}` ;
      this.launchModal(this.delistListingModal, 'delistListingModalTitle', true);
  }

  public onRelistClicked(event: any): void {
    this.listingToDelistOrRelistDisplay =`an ${this.listing.TdrListingType.TdrListingTypeDisplayName} for APN ${this.listing.Parcel.ParcelNumber}` ;
    this.launchModal(this.relistListingModal, 'relistListingModalTitle', false);
}

  private launchModal(modalContent: any, modalTitle: string, isDelisting: boolean): void {
    this.modalReference = this.modalService.open(
      modalContent,
      {
        ariaLabelledBy: modalTitle, beforeDismiss: () => isDelisting ? this.checkIfDelisting() : this.checkIfRelisting(), backdrop: 'static', keyboard: false
      });
  }

  private checkIfDelisting(): boolean {
    return this.isLoadingDelist;
  }

  private checkIfRelisting(): boolean {
    return this.isLoadingRelist;
  }

  public delistListing() {
    this.isLoadingDelist = true;

    this.listingService.listingsTdrListingIDDelistPut(this.tdrListingID).subscribe(() => {
      this.isLoadingDelist = false;
      this.modalReference.close();
      this.alertService.pushAlert(new Alert('Listing was successfully delisted.', AlertContext.Success, true));
      this.refreshListingData();
    }, error => {
      this.isLoadingDelist = false;
    });
  }

  public relistListing() {
    this.isLoadingRelist = true;

    this.listingService.listingsTdrListingIDRelistPut(this.tdrListingID).subscribe(() => {
      this.isLoadingRelist = false;
      this.modalReference.close();
      this.alertService.pushAlert(new Alert('Listing was successfully relisted.', AlertContext.Success, true));
      this.refreshListingData();
    }, error => {
      this.isLoadingRelist = false;
    });
  }
}

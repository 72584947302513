/**
 * LtInfo.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ClimateResilienceGoalSimpleDto } from '../model/climate-resilience-goal-simple-dto';
import { ClimateResilienceOutcomeSimpleDto } from '../model/climate-resilience-outcome-simple-dto';
import { IndicatorSimpleDto } from '../model/indicator-simple-dto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { catchError } from 'rxjs/operators';
import { ApiService } from '../../services';


@Injectable({
  providedIn: 'root'
})
export class IndicatorService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration
    , private apiService: ApiService) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public climateGoalsGet(observe?: 'body', reportProgress?: boolean): Observable<Array<ClimateResilienceGoalSimpleDto>>;
    public climateGoalsGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ClimateResilienceGoalSimpleDto>>>;
    public climateGoalsGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ClimateResilienceGoalSimpleDto>>>;
    public climateGoalsGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ClimateResilienceGoalSimpleDto>>(`${this.basePath}/climate-goals`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param goalID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public climateGoalsGoalIDGet(goalID: number, observe?: 'body', reportProgress?: boolean): Observable<ClimateResilienceGoalSimpleDto>;
    public climateGoalsGoalIDGet(goalID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ClimateResilienceGoalSimpleDto>>;
    public climateGoalsGoalIDGet(goalID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ClimateResilienceGoalSimpleDto>>;
    public climateGoalsGoalIDGet(goalID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (goalID === null || goalID === undefined) {
            throw new Error('Required parameter goalID was null or undefined when calling climateGoalsGoalIDGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ClimateResilienceGoalSimpleDto>(`${this.basePath}/climate-goals/${encodeURIComponent(String(goalID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public climateIndicatorsGet(observe?: 'body', reportProgress?: boolean): Observable<Array<IndicatorSimpleDto>>;
    public climateIndicatorsGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<IndicatorSimpleDto>>>;
    public climateIndicatorsGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<IndicatorSimpleDto>>>;
    public climateIndicatorsGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<IndicatorSimpleDto>>(`${this.basePath}/climate-indicators`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param indicatorID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public climateIndicatorsIndicatorIDGet(indicatorID: number, observe?: 'body', reportProgress?: boolean): Observable<IndicatorSimpleDto>;
    public climateIndicatorsIndicatorIDGet(indicatorID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<IndicatorSimpleDto>>;
    public climateIndicatorsIndicatorIDGet(indicatorID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<IndicatorSimpleDto>>;
    public climateIndicatorsIndicatorIDGet(indicatorID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (indicatorID === null || indicatorID === undefined) {
            throw new Error('Required parameter indicatorID was null or undefined when calling climateIndicatorsIndicatorIDGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<IndicatorSimpleDto>(`${this.basePath}/climate-indicators/${encodeURIComponent(String(indicatorID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public climateOutcomesGet(observe?: 'body', reportProgress?: boolean): Observable<Array<ClimateResilienceOutcomeSimpleDto>>;
    public climateOutcomesGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ClimateResilienceOutcomeSimpleDto>>>;
    public climateOutcomesGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ClimateResilienceOutcomeSimpleDto>>>;
    public climateOutcomesGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ClimateResilienceOutcomeSimpleDto>>(`${this.basePath}/climate-outcomes`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param outcomeID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public climateOutcomesOutcomeIDGet(outcomeID: number, observe?: 'body', reportProgress?: boolean): Observable<ClimateResilienceOutcomeSimpleDto>;
    public climateOutcomesOutcomeIDGet(outcomeID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ClimateResilienceOutcomeSimpleDto>>;
    public climateOutcomesOutcomeIDGet(outcomeID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ClimateResilienceOutcomeSimpleDto>>;
    public climateOutcomesOutcomeIDGet(outcomeID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (outcomeID === null || outcomeID === undefined) {
            throw new Error('Required parameter outcomeID was null or undefined when calling climateOutcomesOutcomeIDGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ClimateResilienceOutcomeSimpleDto>(`${this.basePath}/climate-outcomes/${encodeURIComponent(String(outcomeID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

}

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { CookieService } from 'ngx-cookie-service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './shared/interceptors/auth-interceptor';
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { HomeIndexComponent } from './pages/home-index/home-index.component';
import { ListingListComponent } from './pages/listing-list/listing-list.component';
import { MyListingsComponent } from './pages/my-listings/my-listings.component';
import { RouterModule } from '@angular/router';
import { AgGridModule } from 'ag-grid-angular';
import { DecimalPipe, CurrencyPipe, DatePipe } from '@angular/common';
import { LinkRendererComponent } from './shared/components/ag-grid/link-renderer/link-renderer.component';
import { ExternalLinkRendererComponent } from './shared/components/ag-grid/external-link-renderer/external-link-renderer.component';
import { FormsModule } from '@angular/forms';
import { FontAwesomeIconLinkRendererComponent } from './shared/components/ag-grid/fontawesome-icon-link-renderer/fontawesome-icon-link-renderer.component';
import { LoginCallbackComponent } from './pages/login-callback/login-callback.component';
import { MultiLinkRendererComponent } from './shared/components/ag-grid/multi-link-renderer/multi-link-renderer.component';
import { CreateUserCallbackComponent } from './pages/create-user-callback/create-user-callback.component';
import { AppInitService } from './app.init';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { HttpErrorInterceptor } from './shared/interceptors/httpErrorInterceptor';
import { environment } from 'src/environments/environment';
import { AppInsightsService } from './shared/services/app-insights.service';
import { GlobalErrorHandlerService } from './shared/services/global-error-handler.service';
import { SharedModule } from './shared/shared.module';
import { CookieStorageService } from './shared/services/cookies/cookie-storage.service';
import { TrainingComponent } from './pages/training/training.component';
import { AboutComponent } from './pages/about/about.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgSelectModule } from '@ng-select/ng-select';
import { HelpComponent } from './pages/help/help.component';
import { ApiModule } from './shared/generated/api.module';
import { Configuration } from './shared/generated/configuration';
import { ListingWorkflowOutletComponent } from './pages/listing-workflow-outlet/listing-workflow-outlet.component';
import { ListingBasicsComponent } from './pages/listing-basics/listing-basics.component';
import { ListingInstructionsComponent } from './pages/listing-instructions/listing-instructions.component';
import { ListingOfferDetailsComponent } from './pages/listing-offer-details/listing-offer-details.component';
import { ListingDetailComponent } from './pages/listing-detail/listing-detail.component';
import { ListingsOutletComponent } from './pages/listings-outlet/listings-outlet.component';
import { ManageListingsComponent } from './pages/manage-listings/manage-listings.component';
import { RequestSupportComponent } from './pages/request-support/request-support.component';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { ConverstionCalculatorComponent } from './pages/converstion-calculator/converstion-calculator.component';

export function init_app(appLoadService: AppInitService, appInsightsService:  AppInsightsService) {
  return () => appLoadService.init().then(() => {
    if (environment.appInsightsInstrumentationKey) {
      appInsightsService.initAppInsights();
    }
  });
}

@NgModule({
  declarations: [
    AppComponent,
    HomeIndexComponent,
    ListingListComponent,
    MyListingsComponent,
    ManageListingsComponent,
    ListingWorkflowOutletComponent,
    ListingBasicsComponent,
    ListingOfferDetailsComponent,
    ListingInstructionsComponent,
    ListingDetailComponent,
    LoginCallbackComponent,
    CreateUserCallbackComponent,
    TrainingComponent,
	  AboutComponent,
    ConverstionCalculatorComponent,
    HelpComponent,
    RequestSupportComponent,
    ListingsOutletComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    NgbModule,
    RouterModule,
    OAuthModule.forRoot(),
    SharedModule.forRoot(),
    FormsModule,
    BrowserAnimationsModule,
    AgGridModule.withComponents([]),
    CKEditorModule,
    DragDropModule,
    NgSelectModule,
    ApiModule.forRoot(() => {
      return new Configuration({
          basePath: `${environment.mainAppApiUrl}`,
      });
    }),
    RecaptchaV3Module
  ],  
  providers: [
    CookieService,
    AppInitService,
    { provide: APP_INITIALIZER, useFactory: init_app, deps: [AppInitService, AppInsightsService], multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandlerService
    },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue:environment.recaptchaV3SiteKey},
    DecimalPipe, CurrencyPipe, DatePipe,
    {
      provide: OAuthStorage,
      useClass: CookieStorageService
    }
  ],
  entryComponents: [LinkRendererComponent, ExternalLinkRendererComponent, FontAwesomeIconLinkRendererComponent, MultiLinkRendererComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }

<nav aria-label="breadcrumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item active" aria-current="page">My Listings</li>
    </ol>
</nav>

<div class="container">
    <app-alert-display></app-alert-display>
    <div class="row mb-2">
        <div class="col-12">
            <h2 class="d-inline-block">My Listings</h2>
        </div>
        <div class="col-12">
            <custom-rich-text [customRichTextTypeID]="richTextTypeID"></custom-rich-text>
        </div>
    </div>
    <div class="row mb-2">
        <div class="col-auto ml-auto">
            <tdrmarketplace-clear-grid-filters-button [grid]="listingsGrid" class="mr-2"></tdrmarketplace-clear-grid-filters-button>
            <tdrmarketplace-csv-download-button [grid]="listingsGrid" class=" mr-2" fileName="My Listings"
                colIDsToExclude="[0,1,2]"></tdrmarketplace-csv-download-button>
            <a class="btn btn-tdrmarketplace" href="/listings/new">
                <i class="fa fa-plus"></i> &nbsp;
                Add New Listing
            </a>
        </div>
    </div>
    <div class="table-responsive">
        <ag-grid-angular #listingsGrid style="width: 100%; height: 400px;" class="ag-theme-balham"
            [rowData]="listings" [columnDefs]="columnDefs" [pagination]="true"  (cellClicked)="onCellClicked($event)" 
            [paginationPageSize]="100" [gridOptions]="gridOptions"></ag-grid-angular>
    </div>
</div>

<!-- <div class="container-fluid">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item active" aria-current="page">My Listings</li>
        </ol>
    </nav>
    
    <div >
        <app-alert-display></app-alert-display>
        <div class="row mb-2">
            <div class="col-12">
                <h2 class="d-inline-block">My Listings</h2>
            </div>
            <div class="col-12">
                <custom-rich-text [customRichTextTypeID]="richTextTypeID"></custom-rich-text>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-auto ml-auto">
                <tdrmarketplace-clear-grid-filters-button [grid]="listingsGrid" class="mr-2"></tdrmarketplace-clear-grid-filters-button>
                <tdrmarketplace-csv-download-button [grid]="listingsGrid" class=" mr-2" fileName="My Listings"
                    colIDsToExclude=""></tdrmarketplace-csv-download-button>
                <a class="btn btn-tdrmarketplace" href="/listings/new">
                    <i class="fa fa-plus"></i> &nbsp;
                    Add New Listing
                </a>
            </div>
        </div>
        <div class="table-responsive">
            <ag-grid-angular #listingsGrid style="width: 100%; height: 400px;" class="ag-theme-balham"
                [rowData]="listings" [columnDefs]="columnDefs" [pagination]="true"
                [paginationPageSize]="100" [gridOptions]="gridOptions"></ag-grid-angular>
        </div>
    </div>
</div> -->


<ng-template #deleteListingModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title text-white" id="deleteListingModalTitle">Warning</h5>
        <button type="button" class="close" aria-label="Close" (click)="modal.close('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>
            You are about to delete {{listingToDeleteDisplay}}.
        </p>
        <p>Are you sure you wish to proceed?</p>
        <div class="modal-footer">
            <button type="button" class="btn btn-danger btn-sm" (click)="deleteListing()">
                <span *ngIf="isLoadingDelete" class="fa fa-spinner loading-spinner"></span>
                Delete
            </button>
            <button type="button" class="btn btn-secondary btn-sm" (click)="modal.close('Cancel click')">Cancel</button>
        </div>
    </div>
</ng-template>
import { Component, OnInit, Input, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { Alert } from '../../models/alert';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AlertService } from '../../services/alert.service';
import * as ClassicEditor from 'src/assets/main/ckeditor/ckeditor.js';
import { AlertContext } from '../../models/enums/alert-context.enum';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { FieldDefinitionEnum } from '../../models/enums/field-definition.enum';
import { FieldDefinitionDatumDto, PersonDto } from '../../generated/model/models';
import { FieldDefinitionService } from '../../generated/api/field-definition.service';


declare var $ : any

@Component({
  selector: 'field-definition',
  templateUrl: './field-definition.component.html',
  styleUrls: ['./field-definition.component.scss']
})
export class FieldDefinitionComponent implements OnInit {

  @Input() fieldDefinition: string;
  @Input() labelOverride: string;
  @Input() labelAppend: string;
  @Input() white?: boolean;
  @ViewChild('p') public popover: NgbPopover;
  @ViewChild('popContent') public content: any;
  public fieldDefinitionDatum: FieldDefinitionDatumDto;
  public isLoading: boolean = true;
  public isEditing: boolean = false;
  public emptyContent: boolean = false;
  public watchUserChangeSubscription: any;
  public Editor = ClassicEditor;
  public editedContent: string;
  public editor;

  currentUser: PersonDto;

  public ckConfig = {"removePlugins": ["MediaEmbed", "ImageUpload"]};

  constructor(private fieldDefinitionService: FieldDefinitionService,
    private authenticationService: AuthenticationService,
    private cdr: ChangeDetectorRef,
    private alertService: AlertService,
    private elem: ElementRef) { }

  ngOnInit() {
    this.fieldDefinitionService.fieldDefinitionsFieldDefinitionIDGet(FieldDefinitionEnum[this.fieldDefinition]).subscribe(x => {
      this.loadFieldDefinition(x);
    });
  }

  ngOnDestroy() {
    
    this.cdr.detach();
  }

  // tell CkEditor to use the class below as its upload adapter
  // see https://ckeditor.com/docs/ckeditor5/latest/framework/guides/deep-dive/upload-adapter.html#how-does-the-image-upload-work
  public ckEditorReady(editor) {
    this.editor = editor;
  }

  public getLabelText() {
    return this.labelOverride !== null && this.labelOverride !== undefined
        ? this.labelOverride
        : this.fieldDefinitionDatum.FieldDefinition.FieldDefinitionDisplayName;
}


  private loadFieldDefinition(fieldDefinition:FieldDefinitionDatumDto)
  {
    this.fieldDefinitionDatum = fieldDefinition;
    this.emptyContent = fieldDefinition.FieldDefinitionDatumValue?.length > 0 ? false : true;
    this.isLoading = false;
  }

  public notEditingMouseEnter() {
    if (!this.isEditing) {
      this.popover.open();
      this.elem.nativeElement.closest('body')
                             .querySelector(".popover")
                             .addEventListener('mouseleave', this.mouseLeaveEvent.bind(this));
    }
  }

  public mouseLeaveEvent() {
    if (!this.isEditing) {
      this.popover.close();
    }
  }

  public notEditingMouseLeave() {
      setTimeout( () => {
        let hoveringPopover = this.elem.nativeElement.closest('body')
                                                     .querySelector(".popover:hover")
        if (!hoveringPopover && !this.isEditing) {
            this.popover.close();
        }
    }, 50);
  }
}

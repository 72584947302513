<nav aria-label="breadcrumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item active" aria-current="page">Training</li>
    </ol>
</nav>

<div class="container">
    <app-alert-display></app-alert-display>
    <div class="row mb-2">
        <div class="col">
            <h2 class="d-inline-block">Training</h2>
            <custom-rich-text [customRichTextTypeID]="richTextTypeID"></custom-rich-text>
        </div>
    </div>
</div>